import React, { useRef } from 'react';
import { Stack, SxProps, Theme, Typography, styled, Tooltip, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ActivitySkillType } from '@yellow-owl/client-sdk';
import CompletedActivity from '../../../assets/images/completed-activity.png';
import { NavigationParams } from '../../magazine/IndexPage';
import clickSound from '../../../assets/sounds/clickSound.mp3';

const rightPageIconTitle: SxProps<Theme> = {
  fontWeight: '700',
  fontSize: '2.3vh',
  fontFamily: 'CormorantGaramondRegular',
  textAlign: 'center',
  marginTop: '1vh',
};

const ImageContainer = styled(Box)({
  width: '17vh',
  height: '12vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
});

const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});

const StyledVideo = styled('video')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});

const ActivityCompletedImg = styled(Box)({
  width: '40px',
  height: '40px',
  position: 'absolute',
  top: '5px',
  right: '0.5px',
  '& img': {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
});

type ActivityStatusType = {
  type: string;
  url: string;
};

export type IndexItemComponentProps = {
  itemName: string | undefined;
  itemIconImage?: ActivityStatusType;
  navigateTo: NavigationParams;
  sx?: SxProps<Theme>;
  skill?: Array<ActivitySkillType>;
  isCompleted?: boolean;
};

export const IndexItem: React.FC<IndexItemComponentProps> = ({
  itemName,
  sx,
  itemIconImage,
  navigateTo,
  skill,
  isCompleted,
}) => {
  const navigator = useNavigate();

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const playSound = () => {
    if (audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.error('Audio playback error:', error);
      });
    }
  };

  const onClickItem = () => {
    playSound();
    navigator(navigateTo.pathname + navigateTo.search, {
      state: {
        ...navigateTo.state,
        activityStatus: isCompleted,
      },
    });
  };

  return (
    <Stack
      sx={{
        ...sx,
        width: '16vh',
        alignItems: 'center',
      }}
      onClick={onClickItem}
    >
      <audio ref={audioRef} src={clickSound} preload='auto' />
      {isCompleted && (
        <ActivityCompletedImg>
          <img src={CompletedActivity} alt='ActivityCompleted' />
        </ActivityCompletedImg>
      )}
      <ImageContainer>
        {itemIconImage?.type === 'IMAGE' && <StyledImage src={itemIconImage.url} alt={itemName} />}
        {itemIconImage?.type === 'ANIMATED' && <StyledVideo src={itemIconImage.url} autoPlay loop muted />}
      </ImageContainer>
      <Tooltip title={skill?.join(', ')}>
        <Typography gutterBottom sx={rightPageIconTitle} variant='body1'>
          {itemName}
        </Typography>
      </Tooltip>
    </Stack>
  );
};
