import { Grid, Stack, SxProps, Theme, Typography } from '@mui/material';
import { BookComponent } from '../common/BookComponent';
import keySkillsPieChart from '../../assets/images/pie_chart_key_skills.png';
import { yellowOwlMessages } from '../../Constants';
import { isMobile } from 'react-device-detect';
import { MobileInformataionPage } from '../common/MobileInformationPage';

const leftPageContent: SxProps<Theme> = {
  fontWeight: '600',
  fontSize: '2.5vh',
  fontFamily: 'CormorantGaramondRegular',
  textAlign: 'center',
  marginBottom: '1vh',
  lineHeight: '5vh',
  color: 'black',
};
const rightPageContent: SxProps<Theme> = {
  fontWeight: '600',
  fontSize: '2.5vh',
  fontFamily: 'CormorantGaramondRegular',
  textAlign: 'left',
  marginBottom: '1vh',
  lineHeight: '5vh',
  color: 'black',
};

export const NoteToParent = () => {
  const leftData = (
    <>
      <Typography align={'center'} gutterBottom sx={leftPageContent} variant={'subtitle2'}>
        <b>{yellowOwlMessages.DEAR_PARENT}</b>
        <br></br>
        <b>{yellowOwlMessages.DEAR_PARENT_DESCRIPTION}</b>
        <br></br>
        <b>{yellowOwlMessages.THANK_YOU_YELLOW_OWL}</b>
        <br></br>
        <span style={{ background: '#3C3077', color: 'white', padding: 10, fontSize: '2.7vh' }}>
          {yellowOwlMessages.WHO_ARE_WE}
        </span>
        <br></br>
        <b>{yellowOwlMessages.WHO_ARE_WE_DESCRIPTION}</b>
        <br></br>
        <span style={{ background: '#3C3077', color: 'white', padding: 10, fontSize: '2.7vh' }}>
          {yellowOwlMessages.WHY_YELLOW_OWL}
        </span>
        <br></br>
        <b>
          {yellowOwlMessages.WHY_YELLOW_OWL_DESCRIPTION} <u> {yellowOwlMessages.ENGAGE_WITH_PEERS}</u>{' '}
          {yellowOwlMessages.THROUGH_INTERESTING_AND} <u>{yellowOwlMessages.MEANINGFUL_ACTIVITIES}</u>
          {yellowOwlMessages.TO} <u>{yellowOwlMessages.EXPLORE_AND_BUILD}</u> {yellowOwlMessages.AT_THEIR_OWN_PACE}
        </b>
        <br></br>
      </Typography>
      <Stack sx={leftPageContent}></Stack>
    </>
  );

  const leftContent = (
    <>
      <Grid container spacing={-8}>
        <Grid item xs={11} alignSelf={'center'}>
          {leftData}
        </Grid>
      </Grid>
    </>
  );

  const rightData = (
    <>
      <Typography align={'center'} gutterBottom sx={rightPageContent} variant={'subtitle2'}>
        <Typography align={'center'} gutterBottom sx={leftPageContent} variant={'subtitle2'}>
          <span style={{ background: '#3C3077', color: 'white', padding: 10, fontSize: '2.7vh' }}>
            {yellowOwlMessages.WHY_BUILDING_SKILLS_IS_IMPORTANT}
          </span>
        </Typography>
        <b>{yellowOwlMessages.WHY_BUILDING_SKILLS_IS_IMPORTANT_DESCRIPTION}</b>
        <br></br>
        <b>{yellowOwlMessages.RESEARCH_AND_EVIDENCE}</b>
        <br></br>
        <b>{yellowOwlMessages.RESEARCH_LINKS_HERE}</b>
        <br></br>
        <Typography align={'center'} gutterBottom sx={leftPageContent} variant={'subtitle2'}>
          <span style={{ background: '#3C3077', color: 'white', padding: 10, fontSize: '2.7vh' }}>
            {yellowOwlMessages.KEY_SKILLS}
          </span>
          <br></br>
          <img src={keySkillsPieChart} style={{ width: '20vh', alignSelf: 'center', marginTop: '1vh' }} />
        </Typography>
        <b>{yellowOwlMessages.YELLOW_OWL_COMMUNITY}</b>
        <br></br>
        <b>{yellowOwlMessages.TEAM_YELLOW_OWL}</b>
      </Typography>
    </>
  );

  const rightContent = (
    <>
      <Grid container spacing={-18}>
        <Grid item xs={11} alignSelf={'center'}>
          {rightData}
        </Grid>
      </Grid>
    </>
  );

  const mobileContent = (
    <>
      {leftData}
      <br />
      <br />
      {rightData}
    </>
  );

  return (
    <>
      {!isMobile ? (
        <BookComponent leftContent={leftContent} rightContent={rightContent} hideButtons={false} />
      ) : (
        <MobileInformataionPage content={mobileContent} />
      )}
    </>
  );
};
