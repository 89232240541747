import { BlobServiceClient } from '@azure/storage-blob';

export const uploadToAzureBlob = async (file: File) => {
  try {
    const config = {
      accountName: process.env.REACT_APP_AZURE_STORAGE_ACCOUNT,
      containerName: process.env.REACT_APP_AZURE_CONTAINER_NAME,
      sasToken: process.env.REACT_APP_AZURE_SAS_TOKEN,
    };

    const blobServiceClient = new BlobServiceClient(
      `https://${config.accountName}.blob.core.windows.net?${config.sasToken}`
    );

    const containerClient = blobServiceClient.getContainerClient(config.containerName!);

    const blobName = file.name;

    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    await blockBlobClient.uploadData(file, {
      blobHTTPHeaders: { blobContentType: file.type },
    });

    const URL = `https://${config.accountName}.blob.core.windows.net/${config.containerName}/${blobName}`;
    return URL;
  } catch (e) {
    console.error('Error uploading to Azure Blob:', e);
    throw e;
  }
};
