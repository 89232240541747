import React, { useState } from 'react';
import { Box } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const customAccordionStyles = {
  accordionContainer: {
    marginTop: '10px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  accordionHeader: {
    padding: '10px',
    backgroundColor: '#f1f1f1',
    display: 'flex',
    alignItems: 'center',
  },
  accordionDetails: {
    padding: '10px',
    display: 'none',
    backgroundColor: '#fff',
    borderTop: '1px solid #ccc',
  },
  accordionDetailsOpen: {
    display: 'block',
  },
};

type CustomAccordionProps = {
  answerClue: string;
};

const CustomAccordion = ({ answerClue }: CustomAccordionProps) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);

  return (
    <Box sx={customAccordionStyles.accordionContainer} onClick={() => setIsAccordionOpen(!isAccordionOpen)}>
      <Box sx={customAccordionStyles.accordionHeader}>
        <strong style={{ fontSize: '24px' }}>Show Answer</strong>
        <ArrowDropDownIcon style={{ marginLeft: 'auto' }} />
      </Box>
      <Box
        sx={
          isAccordionOpen
            ? { ...customAccordionStyles.accordionDetails, ...customAccordionStyles.accordionDetailsOpen }
            : customAccordionStyles.accordionDetails
        }
      >
        <strong style={{ fontSize: '24px', fontWeight: '600' }}>
          <strong style={{ fontSize: '24px', fontWeight: '700' }}>{answerClue}</strong>
        </strong>
      </Box>
    </Box>
  );
};

export default CustomAccordion;
