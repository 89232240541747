import { Avatar, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface UserProfileComponentProps {
  userProfileIcon: string;
}
export const UserProfileComponent: React.FC<UserProfileComponentProps> = ({ userProfileIcon }) => {
  const navigate = useNavigate();
  const handleUserProfile = () => {
    navigate('/user-profile');
  };
  return (
    <Box
      sx={{
        m: '5px',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={handleUserProfile}
    >
      <Avatar src={userProfileIcon} alt={'profileIcon'} />
    </Box>
  );
};
