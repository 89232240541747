import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

type TimeTrackerContextType = {
  timeSpent: number;
  setTimerActive: (active: boolean) => void;
  resetTimer: () => void;
};

const TimeTrackerContext = createContext<TimeTrackerContextType | undefined>(undefined);

export const TimeTrackerProvider = ({
  children,
  userId,
  magazineId,
  activityId,
}: {
  children: ReactNode;
  userId: string;
  magazineId: string;
  activityId: string;
}) => {
  const key = `${userId}_${magazineId}_${activityId}`;

  const [timeSpent, setTimeSpent] = useState<number>(() => {
    const storedTime = localStorage.getItem('timeSpent');
    if (storedTime) {
      const userTimeSpent = JSON.parse(storedTime);
      return userTimeSpent[key] ? parseInt(userTimeSpent[key], 10) : 0;
    }
    return 0;
  });

  const [timerActive, setTimerActive] = useState<boolean>(true);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (timerActive) {
      timer = setInterval(() => {
        setTimeSpent((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [timerActive]);

  const handleBeforeUnload = () => {
    let userTimeSpent: any = localStorage.getItem('timeSpent');
    if (userTimeSpent) {
      userTimeSpent = JSON.parse(userTimeSpent);
    } else {
      userTimeSpent = {};
    }
    userTimeSpent[key] = timeSpent.toString();
    localStorage.setItem('timeSpent', JSON.stringify(userTimeSpent));
  };

  useEffect(() => {
    if (timeSpent % 1 === 0) {
      handleBeforeUnload();
    }
  }, [timeSpent]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [timeSpent]);

  const resetTimer = () => {
    setTimeSpent(0);
  };

  return (
    <TimeTrackerContext.Provider value={{ timeSpent, setTimerActive, resetTimer }}>
      {children}
    </TimeTrackerContext.Provider>
  );
};

export const useTimeTracker = (): TimeTrackerContextType => {
  const context = useContext(TimeTrackerContext);
  if (!context) {
    throw new Error('useTimeTracker must be used within a TimeTrackerProvider');
  }
  return context;
};
