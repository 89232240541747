import React, { useState } from 'react';
import { IconButton, SxProps, Theme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { INDEX_PAGE } from '../../route/router';
import ListIcon from '@mui/icons-material/List';
import { useLocalStorage } from '../../context/LocalStorageContext';
import NavigationConfirmationModal from './confirmation-modal/NavigationConfirmationModal';

const buttonStyle: SxProps<Theme> = {
  backgroundColor: '#FBEC53',
  color: '#3C3077',
  '&:hover': { backgroundColor: '#FBEC53' },
  '&:focus': { backgroundColor: '#FBEC53' },
};

export const IndexPageButtonComponent: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const { state } = useLocalStorage();
  const magazineId = searchParams.get('id') || '0';
  const activityId = searchParams.get('actid') || '0';
  const userId = state.userId!;
  const tenantId = state.tenantId!;
  const categoryId = state.categoryId!;

  const handleHomeButtonClick = () => {
    if (location.pathname === INDEX_PAGE && id) {
      navigate(`${INDEX_PAGE}?id=${id}`);
    } else {
      setIsPromptOpen(true);
    }
  };

  const handleConfirmationModalClose = (confirm: boolean) => {
    setIsPromptOpen(false);
    if (confirm) {
      navigate(`${INDEX_PAGE}?id=${id}`);
    }
  };

  return (
    <>
      <IconButton sx={buttonStyle} aria-label='upload picture' component='label' onClick={handleHomeButtonClick}>
        <ListIcon />
      </IconButton>
      <NavigationConfirmationModal
        title=''
        content='Are you sure you want to leave this page?'
        open={isPromptOpen}
        onClose={handleConfirmationModalClose}
        userId={userId}
        magazineId={magazineId}
        activityId={activityId}
        tenantId={+tenantId}
        categoryId={+categoryId}
      />
    </>
  );
};

export default IndexPageButtonComponent;
