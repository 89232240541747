import { Navigate, Outlet } from 'react-router-dom';

export type GuardedRouteProps = {
  isRouteAccessible: boolean;
  redirectRoute: string;
};

export const GuardedRoute: React.FC<GuardedRouteProps> = ({ isRouteAccessible, redirectRoute }): JSX.Element => {
  return isRouteAccessible ? <Outlet /> : <Navigate to={redirectRoute} replace />;
};
