import { Box, Card, CardContent, CardMedia, Typography, Chip, SxProps, Theme } from '@mui/material';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

const videoPreviewContainer: SxProps<Theme> = {
  height: '140px',
  bgcolor: 'grey.800',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
};

const videoOverlay: SxProps<Theme> = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  bgcolor: 'rgba(0, 0, 0, 0.4)',
};

const noMediaContainer: SxProps<Theme> = {
  height: '140px',
  bgcolor: 'grey.100',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: 1,
};

const card: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  cursor: 'pointer',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: 4,
  },
};

const cardMedia: SxProps<Theme> = {
  objectFit: 'cover',
  bgcolor: '#4CC9FE',
};

const cardTitle: SxProps<Theme> = {
  fontWeight: 600,
  fontSize: '1.1rem',
  mb: 1,
  color: 'primary.main',
  letterSpacing: 0.3,
};

const cardActivity: SxProps<Theme> = {
  color: 'text.primary',
  fontWeight: 500,
  fontSize: '0.875rem',
  mb: 0.5,
};

const cardMagazine: SxProps<Theme> = {
  color: 'text.secondary',
  fontSize: '0.75rem',
  fontWeight: 400,
  display: 'block',
  fontStyle: 'italic',
};

const mediaTypeChip = (type: 'IMAGE' | 'VIDEO') => ({
  position: 'absolute',
  top: 8,
  left: 8,
  backgroundColor: type === 'IMAGE' ? '#4CC9FE' : 'yellow',
  color: 'black',
});

type Media = {
  type: 'IMAGE' | 'VIDEO';
  url: string[] | null;
};

type UserResponse = {
  tenantId: number;
  categoryId: number;
  userId: number;
  userName: string;
  magazineId: number;
  magazineName: string;
  activityId: number;
  activityName: string;
  media: Media[];
};

const VideoPreview: React.FC = () => (
  <Box sx={videoPreviewContainer}>
    <Box sx={videoOverlay}>
      <PlayCircleIcon sx={{ fontSize: 48, color: 'white' }} />
    </Box>
  </Box>
);

const NoMediaPlaceholder: React.FC<{ type: 'IMAGE' | 'VIDEO' }> = ({ type }) => (
  <Box sx={noMediaContainer}>
    {type === 'IMAGE' ? (
      <ImageNotSupportedIcon sx={{ fontSize: 40, color: 'grey.500' }} />
    ) : (
      <VideoFileIcon sx={{ fontSize: 40, color: 'grey.500' }} />
    )}
    <Typography variant='caption' color='text.secondary'>
      No {type.toLowerCase()} available
    </Typography>
  </Box>
);

export const MediaCard: React.FC<{
  teacher: UserResponse;
  mediaItem: Media;
  onClick: () => void;
}> = ({ teacher, mediaItem, onClick }) => (
  <Card sx={card} onClick={onClick}>
    <Box sx={{ position: 'relative' }}>
      {mediaItem.type === 'IMAGE' && mediaItem.url && mediaItem.url.length > 0 ? (
        <CardMedia component='img' height='140' image={mediaItem.url[0]} alt={teacher.userName} sx={cardMedia} />
      ) : mediaItem.type === 'VIDEO' && mediaItem.url && mediaItem.url.length > 0 ? (
        <VideoPreview />
      ) : (
        <NoMediaPlaceholder type={mediaItem.type} />
      )}
      <Chip
        label={mediaItem.type}
        size='small'
        color={mediaItem.type === 'IMAGE' ? 'primary' : 'secondary'}
        sx={mediaTypeChip(mediaItem.type)}
      />
    </Box>
    <CardContent sx={{ flexGrow: 1, p: 2 }}>
      <Typography variant='h6' noWrap sx={cardTitle}>
        {teacher.userName}
      </Typography>
      <Typography variant='body2' noWrap sx={cardActivity}>
        {teacher.activityName}
      </Typography>
      <Typography variant='caption' sx={cardMagazine}>
        {teacher.magazineName}
      </Typography>
    </CardContent>
  </Card>
);
