import { Box, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import GoogleplayStore from '../../assets/icons/Google-playstore.png';
import AppleAppStore from '../../assets/icons/Apple-store.png';
import YellowOwlIcon from '../../assets/icons/yowl-logo-new.png';
import YellowOwlTextIcon from '../../assets/icons/Yellow Owl Title.svg';
import laptop from '../../assets/icons/laptop.svg';
import rocket from '../../assets/icons/rocket.svg';

const container = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  boxSizing: 'border-box',
  overflowY: 'auto',
};

const titleText = {
  fontSize: '1.3rem',
  fontWeight: '700',
  padding: '0.3rem 1rem',
  color: 'black',
  fontStyle: 'normal',
};

const downloadText = {
  fontSize: '1.8rem',
  fontWeight: '700',
  padding: '1.5rem 1rem',
  color: 'black',
  fontStyle: 'normal',
};

const owlImage = {
  width: '50%',
  paddingTop: '2.5rem',
  paddingBottom: '2.75rem',
  alignSelf: 'center',
};

const laptopIcon: SxProps<Theme> = {
  img: {
    width: '5vw',
  },
  position: 'absolute',
  top: '5vh',
  left: '5vw',
};

const rocketIcon: SxProps<Theme> = {
  img: {
    width: '5vw',
  },
  position: 'absolute',
  bottom: '5vh',
  left: '4vw',
};

const titleContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};
const owlIconContainer = {
  marginTop: '2rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const YellowOwlTextIconStyles = {
  width: '60%',
  marginTop: '20px',
};

const MobileWarning = () => {
  const isMobile = useMediaQuery('(max-width:768px)');

  if (!isMobile) {
    return null;
  }

  return (
    <Box sx={container}>
      <Box sx={laptopIcon}>
        <img src={laptop} alt='laptop' />
      </Box>
      <Box sx={rocketIcon}>
        <img src={rocket} alt='rocket' />
      </Box>
      <Box sx={titleContainer}>
        <img style={YellowOwlTextIconStyles} src={YellowOwlTextIcon} alt='yellow-owl-text-icon' />
        <img style={owlImage} src={YellowOwlIcon} alt='yellow-owl-icon' />
        <Typography align='center' sx={titleText}>
          Our application is best experienced on desktop. Please switch to a desktop device or download our mobile app
          for a better experience.
        </Typography>
      </Box>
      <Box sx={owlIconContainer}>
        <Typography sx={downloadText}>Download our app</Typography>
        <a href='https://play.google.com/store/apps/details?id=com.yowl.yellowowl'>
          <img src={GoogleplayStore} alt='yellow-owl' />
        </a>
        <a href='https://apps.apple.com/in/app/yellow-owl/id6737185115'>
          <img src={AppleAppStore} alt='yellow-owl' />
        </a>
      </Box>
    </Box>
  );
};

export default MobileWarning;
