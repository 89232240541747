import React, { useState, useContext, useEffect } from 'react';
import { Box, Paper, FormControl, RadioGroup, FormControlLabel, Radio, Grid } from '@mui/material';
import { YellowOwlContext } from '../../../context/YellowOwlContext';
import { useAsyncEffect } from '../../../hooks/use-async';
import { ActivitySkillType, InputContent, McqQuestion, QuestionFormat } from '@yellow-owl/client-sdk';
import { SxProps, Theme } from '@mui/material';
import CustomAccordion from './CustomAccordion';

const paperStyle: SxProps<Theme> = {
  padding: '10px',
  borderRadius: '12px',
  marginLeft: '10px',
};

const radioGroupContainerStyle: SxProps<Theme> = {
  width: '35vw',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  flexWrap: 'wrap',
};

const radioItemStyle: SxProps<Theme> = {
  marginBottom: '15px',
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const formControlLabelStyle = (
  selectedOption: string | null,
  option: any,
  isCorrect: boolean | undefined,
  showFeedback: boolean
): SxProps<Theme> => {
  return {
    backgroundColor:
      showFeedback && selectedOption === option.option
        ? isCorrect
          ? 'rgba(0, 255, 0, 0.3)'
          : 'rgba(255, 0, 0, 0.3)'
        : 'transparent',
    border: '2px solid #ccc',
    borderRadius: '16px',
    padding: '2px',
    maxWidth: '15vw',
    minWidth: '10vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    margin: '5px',
    height: 'auto',
  };
};

export type MagazineMCQProps = {
  questionFormat: QuestionFormat;
  inputContent: Array<InputContent>;
  questionFormatId: number;
  userId: string;
  magazineId: string;
  activityId: string;
  tenantId: number;
  categoryId: number;
  skill: Array<ActivitySkillType>;
  questionId: number;
  globalInputState: boolean;
  onUpdateInputContents: (input: Array<any>) => boolean;
};

const MagazineMCQ = (props: MagazineMCQProps) => {
  const {
    questionFormat,
    inputContent = [],
    questionFormatId,
    userId,
    magazineId,
    activityId,
    tenantId,
    categoryId,
    skill,
    questionId,
    globalInputState,
    onUpdateInputContents,
  } = props;

  const yellowOwlApi = useContext(YellowOwlContext)!;
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [disabledOptions, setDisabledOptions] = useState<string[]>([]);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);

  useEffect(() => {
    inputContent.forEach((inputElt: InputContent) => {
      inputElt.questionData.forEach((questionData) => {
        if (questionData.id === +questionId) {
          questionData.questionFormat.forEach((questionFormat) => {
            if (questionFormat.format.id === +questionFormatId) {
              const response = questionFormat.format.response;
              if (response && response.length > 0) {
                setSelectedOption(response[0]);
              }
              // Set disabled options based on the API response
              const allOptions = (questionFormat.format as McqQuestion).options.map((option: any) => option.option);
              if (response && response.length > 0) {
                setDisabledOptions(allOptions);
              }
            }
          });
        }
      });
    });
  }, [inputContent, questionId, questionFormatId]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    inputContent.forEach((inputElt: InputContent) => {
      inputElt.questionData.forEach((questionData) => {
        if (questionData.id === +questionId) {
          questionData.questionFormat.forEach((questionFormat) => {
            if (questionFormat.format.id === +questionFormatId) {
              questionFormat.format.response = [selectedValue];
            }
          });
        }
      });
    });
    setShowFeedback(false);
    if (questionFormat.format.mandatory) {
      setIsValid(selectedValue.trim().length > 0);
    } else {
      setIsValid(true);
    }

    // Call function to disable all options after a delay
    disableAllOptionsAfterDelay();
  };

  const disableAllOptionsAfterDelay = () => {
    setTimeout(() => {
      // Get all options from the question format and set them as disabled
      const allOptions = (questionFormat.format as McqQuestion).options.map((option: any) => option.option);
      setDisabledOptions(allOptions);
      setShowFeedback(true);
    }, 10000); // 10 seconds delay
  };

  useEffect(() => {
    if (globalInputState) {
      let isValid = true; // Start by assuming the form is valid
      inputContent.forEach((inputElt: InputContent) => {
        inputElt.questionData.forEach((questionData) => {
          if (questionData.id === questionId) {
            questionData.questionFormat.forEach((questionFormat) => {
              if (questionFormat.format.id === questionFormatId) {
                // Check if the question format is mandatory
                if (questionFormat.format.mandatory) {
                  // Check if response is non-empty
                  const isValidResponse = Boolean(
                    questionFormat.format.response && questionFormat.format.response[0]?.trim() !== ''
                  );

                  isValid = isValid && isValidResponse; // Update isValid
                }
              }
            });
          }
        });
      });

      if (questionFormat.format.mandatory) {
        setIsValid(isValid);
      } else {
        setIsValid(true);
      }
    }
  }, [globalInputState, inputContent, questionId, questionFormatId]);

  useAsyncEffect({
    fn: async () => {
      if (selectedOption !== null && inputContent.length > 0) {
        const result = await yellowOwlApi.addUserResponse({
          userId: +userId,
          magazineId: +magazineId,
          activityId: activityId,
          inputContent: inputContent,
          tenantId: +tenantId,
          categoryId: +categoryId,
          skill: skill,
        });
        if (result) {
          onUpdateInputContents(inputContent);
        }
        return result.data;
      }
    },
    dependencies: [selectedOption],
  });

  // Extract the answer description
  const answerClue = (questionFormat.format as McqQuestion).answerDescription || 'Not Available';

  const containerStyle: SxProps<Theme> = {
    width: '33vw',
    padding: '5px',
    border: isValid ? 'transparent' : '1px solid red',
    borderRadius: '16px',
    marginTop: '10px',
  };

  return (
    <Box sx={containerStyle}>
      <Paper elevation={0} sx={paperStyle}>
        <FormControl component='fieldset'>
          <RadioGroup value={selectedOption} onChange={handleOptionChange}>
            <Grid container sx={radioGroupContainerStyle}>
              {questionFormat.format.type === 'MCQ' &&
                questionFormat.format?.options.map((option: any) => (
                  <Grid item xs={6} key={option.option} sx={radioItemStyle}>
                    <span>
                      <FormControlLabel
                        value={option.option}
                        control={<Radio />}
                        label={option.option}
                        disabled={disabledOptions.includes(option.option)} // Disable based on state
                        sx={formControlLabelStyle(selectedOption, option, option.isValid, showFeedback)}
                      />
                    </span>
                  </Grid>
                ))}
            </Grid>
          </RadioGroup>
        </FormControl>

        {disabledOptions.length === (questionFormat.format as McqQuestion).options.length && (
          <CustomAccordion answerClue={answerClue} />
        )}
      </Paper>
    </Box>
  );
};

export default MagazineMCQ;
