import produce from 'immer';
import { ConfirmationResult, RecaptchaVerifier } from 'firebase/auth';
import { LSAuthDetails, LSBasicUserDetailsKey, getFromLocalStorage } from '../../utils/localStorageUtils';
import { UserData } from '../User/UserContext';

export type AuthDetails = {
  token: string;
};

export type AuthState = {
  phoneNumber: string;
  countryCode: string;
  countryCodeValue: string;
  otpValue: string;
  // state for user data from firebase after logging in
  authDetails: AuthDetails | null;
  // state for data which we get from user to save
  basicUserData: UserData | null;
  firebaseSignInResult?: ConfirmationResult;
  capchaVerifierResult?: RecaptchaVerifier;
  isNewUserSaved?: boolean;
  // above value is set when we want to fetch the user details after new user is saved
};

export const initialAuthState: AuthState = {
  phoneNumber: '',
  countryCode: 'IN',
  countryCodeValue: '+91',
  otpValue: '',
  authDetails: getFromLocalStorage<AuthDetails>(LSAuthDetails, true) as AuthDetails | null,
  basicUserData: getFromLocalStorage<UserData>(LSBasicUserDetailsKey, true) as UserData | null,
  isNewUserSaved: false,
};

export type AuthActionType = {
  type:
    | 'phone_number'
    | 'otp'
    | 'country_code'
    | 'country_code_value'
    | 'confirmation_result'
    | 'capcha-result'
    | 'login'
    | 'update_basic_details'
    | 'logout'
    | 'new_user_saved';

  value?: any;
};

export const AuthReducer = (state: AuthState, action: AuthActionType) => {
  const { type, value } = action;

  switch (type) {
    case 'phone_number':
      return produce(state, (draft) => {
        draft.phoneNumber = value;
        return draft;
      });
    case 'otp':
      return produce(state, (draft) => {
        draft.otpValue = value;
        return draft;
      });
    case 'country_code':
      return produce(state, (draft) => {
        draft.countryCode = value;
        return draft;
      });
    case 'country_code_value':
      return produce(state, (draft) => {
        draft.countryCodeValue = value;
        return draft;
      });
    case 'confirmation_result':
      return produce(state, (draft) => {
        draft.firebaseSignInResult = value;
        return draft;
      });
    case 'capcha-result':
      return produce(state, (draft) => {
        draft.capchaVerifierResult = value;
        return draft;
      });
    case 'login':
      return produce(state, (draft) => {
        draft.authDetails = value;
        return draft;
      });
    case 'update_basic_details':
      return produce(state, (draft) => {
        draft.basicUserData = value;
        return draft;
      });
    case 'logout':
      return produce(state, (draft) => {
        draft.authDetails = null;
        draft.basicUserData = null;
        return draft;
      });
    case 'new_user_saved':
      return produce(state, (draft) => {
        draft.isNewUserSaved = true;
        return draft;
      });
  }
};
