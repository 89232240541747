import React, { useContext, useState, useEffect } from 'react';
import { ActivityPage } from '../../components/magazine/ActivityPage';
import { NavigationPage } from '../../components/common/navigation-page/NavigationPage';
import { useAsyncEffect } from '../../hooks/use-async';
import { YellowOwlContext } from '../../context/YellowOwlContext';
import { useSearchParams } from 'react-router-dom';
import { ActivityContent, InputContent } from '@yellow-owl/client-sdk';
import MandatoryConfirmation from '../../components/common/mandatory-confirmation-modal/MandatoryConfirmation';
import { updateLocalStorage, LSInputContents } from '../../utils/localStorageUtils';
import { useLocalStorage } from '../../context/LocalStorageContext';
import { TimeTrackerProvider } from '../../context/UserTImeSpentContextProvider';
import { useEventSenders } from '../../services/userEventsService';

export const ActivityPageContainer: React.FC = () => {
  const [activityContent, setActivityContent] = useState<ActivityContent>();
  const [inputContents, setInputContents] = useState<Array<InputContent>>([]);
  const { state } = useLocalStorage();
  const [searchParams] = useSearchParams();
  const yellowOwlApi = useContext(YellowOwlContext)!;
  const magazineId = searchParams.get('id') || '0';
  const activityId = searchParams.get('actid') || '0';
  const userId = state.userId!;
  const tenantId = state.tenantId!;
  const categoryId = state.categoryId!;

  const [globalInputState, setGlobalInputState] = useState<boolean>(false);
  const [showFormInvalidModalForFeedback, setShowFormInvalidModalForFeedback] = useState(false);
  const [canShowFeedbackModal, setCanShowFeedbackModal] = useState(false);
  const { sendActivityPageEvent } = useEventSenders();

  const handleOpenConfirmationModal = () => {
    ``;
    setShowFormInvalidModalForFeedback(true);
  };

  const handleConfirmationModalClose = () => {
    setShowFormInvalidModalForFeedback(false);
  };

  const handleCloseFeedbackSubmitModal = () => {
    setCanShowFeedbackModal(false);
  };

  const [activityContentState] = useAsyncEffect<ActivityContent>({
    fn: async () => {
      try {
        if (userId && tenantId && categoryId && magazineId) {
          const result = await yellowOwlApi.getContentForActivity(tenantId, userId, categoryId, magazineId, activityId);
          return result.data as any;
        }
      } catch (e) {
        console.log(e);
      }
    },
    dependencies: [],
  });

  useEffect(() => {
    const inputContentsCollection: Array<InputContent | any> = [];
    if (activityContent) {
      activityContent.data.forEach((item) => {
        // Collect input data for 'Input' type pages
        if (item.pageData.type === 'Input') {
          item.pageData.data.forEach((inputData) => {
            inputContentsCollection.push(inputData);
          });
        }
        // Collect input data for 'Mixed' type pages
        if (item.pageData.type === 'Mixed') {
          item.pageData.data.forEach((mixedPageData) => {
            if (mixedPageData.type === 'Input') {
              mixedPageData.data.forEach((inputData) => {
                inputContentsCollection.push(inputData);
              });
            }
          });
        }
      });
      updateLocalStorage<Array<InputContent>>(LSInputContents, inputContentsCollection, true);
      setInputContents(inputContentsCollection);
    }
  }, [activityContent]);

  useEffect(() => {
    if (activityContentState.result) {
      setActivityContent(activityContentState.result as ActivityContent);
    }
  }, [activityContentState]);

  const handleCheckMandatory = () => {
    for (const input of inputContents) {
      for (const question of input.questionData) {
        for (const format of question.questionFormat) {
          const isMandatory = format.format.mandatory;
          if (isMandatory) {
            const response = format.format.response;
            if (Array.isArray(response)) {
              if (response.some((res) => !res || res.trim() === '')) {
                handleOpenConfirmationModal();
                setGlobalInputState(true);
                return;
              }
            } else {
              if (!response || response.trim() === '') {
                handleOpenConfirmationModal();
                setGlobalInputState(true);
                return;
              }
            }
          }
        }
      }
    }
    setCanShowFeedbackModal(true);
    return;
  };

  const handleUpdateInputContents = (inputContentState: Array<InputContent>): boolean => {
    try {
      setInputContents(inputContentState);
      return true;
    } catch (error) {
      console.error('Failed to update input contents', error);
      return false;
    }
  };
  activityContent?.skill.map((sk) => {
    console.log(sk);
  });

  useEffect(() => {
    const skills = activityContent?.skill.map((sk) => sk);

    if (categoryId && magazineId && activityId && skills?.length) {
      sendActivityPageEvent(categoryId, magazineId, activityId, skills);
    }
  }, [categoryId, magazineId, activityId, activityContent]);

  return (
    <>
      <TimeTrackerProvider userId={userId} magazineId={magazineId} activityId={activityId}>
        {activityContent && magazineId && state.userId && state.tenantId && state.categoryId && (
          <NavigationPage totalPages={activityContent.noOfPages}>
            <ActivityPage
              magazineId={magazineId}
              activityId={activityId!}
              userId={state.userId}
              activityContent={activityContent!}
              inputContents={inputContents}
              tenantId={Number(state.tenantId)}
              categoryId={Number(state.categoryId)}
              canShowFeedbackModal={canShowFeedbackModal}
              globalInputState={globalInputState}
              onCloseFeedbackSubmitModal={handleCloseFeedbackSubmitModal}
              onCheckMandatory={handleCheckMandatory}
              onUpdateInputContents={handleUpdateInputContents}
            ></ActivityPage>
          </NavigationPage>
        )}
        <MandatoryConfirmation
          title='Hiya!'
          content='You have not fully completed the activity!'
          open={showFormInvalidModalForFeedback}
          onClose={handleConfirmationModalClose}
        />
      </TimeTrackerProvider>
    </>
  );
};
