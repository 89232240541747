import React from 'react';
import { CoverPage } from '../../components/magazine/CoverPage';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Magazine } from '@yellow-owl/client-sdk';

export const CoverPageContainer: React.FC = () => {
  const [searchParams] = useSearchParams();

  const magazine: Magazine = {
    coverPicUrl: searchParams.get('coverPicUrl')!,
    createdAt: '',
    thumbnailUrl: '',
    title: searchParams.get('name')!,
    updatedAt: '',
    id: Number(searchParams.get('id')!),
    locale: '',
  };

  const navigate = useNavigate();
  const onCoverClick = (magazineId: number) => {
    navigate({
      pathname: '/magazine/index',
      search: `?id=${magazineId}&name=${encodeURIComponent(magazine.title)}`,
    });
  };

  return <CoverPage magazine={magazine} onCoverClick={onCoverClick} />;
};
