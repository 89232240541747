import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';

interface NavigationContextType {
  isBlocking: boolean;
  setIsBlocking: React.Dispatch<React.SetStateAction<boolean>>;
  openNavigationPrompt: () => void;
  confirmNavigation: () => void;
  cancelNavigation: () => void;
  isPromptOpen: boolean;
  setIsPromptOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPendingNavigation: any;
}

const NavigationContext = createContext<NavigationContextType | undefined>(undefined);

export const NavigationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isBlocking, setIsBlocking] = useState<boolean>(false);
  const [pendingNavigation, setPendingNavigation] = useState<(() => void) | null>(null);
  const [isPromptOpen, setIsPromptOpen] = useState<boolean>(false);

  const openNavigationPrompt = useCallback(() => {
    setIsPromptOpen(true);
  }, []);

  const confirmNavigation = useCallback(() => {
    setIsBlocking(false);
    if (pendingNavigation) {
      pendingNavigation();
      setPendingNavigation(null);
    }
    setIsPromptOpen(false);
  }, [pendingNavigation]);

  const cancelNavigation = useCallback(() => {
    setIsPromptOpen(false);
    setPendingNavigation(null);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      console.log(event, 'heyy here');
      if (isBlocking) {
        event.preventDefault();
        event.returnValue = ''; // Some browsers require returnValue to be set
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isBlocking]);

  return (
    <NavigationContext.Provider
      value={{
        isBlocking,
        setIsBlocking,
        openNavigationPrompt,
        confirmNavigation,
        cancelNavigation,
        isPromptOpen,
        setIsPromptOpen,
        setPendingNavigation,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const LocaluseNavigation = (): NavigationContextType => {
  const context = useContext(NavigationContext);
  if (context === undefined) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};
