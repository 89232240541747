import { Box, Typography, SxProps, Theme, Avatar } from '@mui/material';
import coin from '../../assets/icons/coin.svg';
import { theme } from '../../theme';
import { LeaderBoardUserItem } from '@yellow-owl/client-sdk';

const IndividualLeaderboardPositionCardStyles: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  ml: '4vw',
  mb: '3vh',
  justifyContent: 'space-between',
  width: '60%',
  [theme.breakpoints.down('sm')]: {
    width: '60vw',
    ml: '1vw',
  },
};

const IndividualLeaderboardPositionAvatarStyles: SxProps<Theme> = {
  bgcolor: 'lightblue',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '35px',
  height: '35px',
};

const IndividualLeaderboardPositionTextStyles: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  px: '8px',
};

const IndividualLeaderboardPositionUserPositionStyles: SxProps<Theme> = {
  bgcolor: '#f5b452',
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '20px',
};

const IndividualLeaderboardPositionAvatarWidth: SxProps<Theme> = {
  width: '30px',
  height: '30px',
};

const IndividualLeaderboardPositionCardsOrientation: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
};

export const IndividualLeaderboardPositionCard: React.FC<LeaderBoardUserItem> = ({ position, avatar, name, coins }) => {
  return (
    <Box key={position} sx={IndividualLeaderboardPositionCardStyles}>
      <Box sx={IndividualLeaderboardPositionCardsOrientation}>
        <Box sx={IndividualLeaderboardPositionAvatarStyles}>
          <Avatar sx={IndividualLeaderboardPositionAvatarWidth} src={avatar} />
        </Box>
        <Box sx={IndividualLeaderboardPositionTextStyles}>
          <Typography sx={{ fontWeight: 'bold', fontFamily: 'RalewayRegular', fontSize: '14px' }}>{name}</Typography>
          <Typography sx={{ fontFamily: 'RalewayRegular', fontSize: '12px' }}>
            {coins} <img src={coin} alt={coin} style={{ width: '16px', height: '16px' }} />
          </Typography>
        </Box>
      </Box>
      <Box sx={IndividualLeaderboardPositionUserPositionStyles}>
        <Typography sx={{ fontFamily: 'RalewayRegular', fontSize: '20px', color: 'white' }}>{position}</Typography>
      </Box>
    </Box>
  );
};
