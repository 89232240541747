import { Box, Grid, Stack, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import ReactFlagsSelect from 'react-flags-select';
import 'react-phone-input-2/lib/bootstrap.css';
import curevLine from '../../assets/icons/curve-line.svg';
import { yellowOwlMessages } from '../../Constants';
import YellowOwlInput from '../common/input/YellowOwlInput';
import { LeftPanelWalkthrough } from '../common/LeftPanelWalkthrough';
import { OwlHeader } from '../common/OwlHeader';

// TODO : move this to utils
export type CountryCode = {
  key: string;
  value: string;
};

export const SUPPORTED_COUNTRY_CODES: CountryCode[] = [
  // TODO: removing support for us now
  { key: 'US', value: '+1' },
  { key: 'IN', value: '+91' },
  { key: 'CA', value: '+1' },
  { key: 'DE', value: '+49' },
];

const formBox: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};

const enterMobileNo: SxProps<Theme> = {
  fontFamily: 'CormorantGaramondRegular',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '1.5vw',
  lineHeight: '16px',
};

const flagSelect: SxProps<Theme> = {
  button: {
    background: '#FFFFFF',
    height: '8vh',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    letterSpacing: '1px',
    border: '2px solid #3C3077',
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
  },
  position: 'relative',
  width: '8vw',
  svg: {
    height: '5vh',
    width: '3vw',
  },
};

export type GetOTPPageComponentProps = {
  phoneNumber: string;
  countryCode: string;
  canSignIn: boolean;
  onCountryCodeChange(countryCode: string): void;
  onPhoneNumberChange(number: string): void;
  onRequestSignIn(): void;
};

export const GetOTPPageComponent: React.FC<GetOTPPageComponentProps> = ({
  phoneNumber,
  countryCode,
  canSignIn,
  onCountryCodeChange,
  onPhoneNumberChange,
  onRequestSignIn,
}) => {
  return (
    <Box
      sx={{
        background: `url(${curevLine}), linear-gradient(to right,#3C3077 0%,#3C3077 50%, #FBEC53 50%, #FBEC53 100%)`,
        height: '100vh',
      }}
    >
      <Grid container>
        <Grid container item>
          <Grid item xs={12}>
            <OwlHeader />
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={6}>
            <LeftPanelWalkthrough />
          </Grid>
          <Grid item xs={6} sx={formBox}>
            <Box>
              <Typography align='left' marginBottom='16px' variant='subtitle1' component='p' sx={enterMobileNo}>
                {yellowOwlMessages.PLEASE_ENTER_YOUR_MOBILE_NUMBER}
              </Typography>
              <Stack spacing={1} direction='row'>
                <Box sx={flagSelect}>
                  <ReactFlagsSelect
                    selected={countryCode}
                    countries={SUPPORTED_COUNTRY_CODES.map((item) => item.key)}
                    onSelect={(code) => onCountryCodeChange(code)}
                    showOptionLabel={false}
                    showSelectedLabel={false}
                  />
                </Box>
                <Box>
                  <YellowOwlInput
                    type={'tel'}
                    placeholder={yellowOwlMessages.PhoneNo}
                    value={phoneNumber}
                    onChangeInput={(e) => onPhoneNumberChange(e.target.value)}
                    submitButton
                    submitButtonAction={onRequestSignIn}
                    disableSubmitButton={!canSignIn}
                  />
                </Box>
              </Stack>
              <br />
              <div id='recaptcha-container'></div>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
