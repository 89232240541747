import { useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LocaluseNavigation } from '../context/NavigationContextType';
export const useCustomBlocker = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isBlocking, openNavigationPrompt, setPendingNavigation } = LocaluseNavigation();

  const handleNavigation = useCallback(() => {
    if (isBlocking) {
      setPendingNavigation(() => () => navigate(location.pathname));
      openNavigationPrompt();
    }
  }, [isBlocking, openNavigationPrompt, setPendingNavigation, navigate, location.pathname]);

  useEffect(() => {
    handleNavigation();
  }, [handleNavigation]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isBlocking) {
        event.preventDefault();
        event.returnValue = ''; // Some browsers require returnValue to be set
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isBlocking]);
};
