import curevLine from '../../assets/icons/curve-line.svg';
import { Box, Grid, SxProps, Theme, Typography } from '@mui/material';
import owlHeader from '../../assets/icons/owl-header.svg';
import { Magazine } from '@yellow-owl/client-sdk';
import { useRef } from 'react';
import magazineOpeningSoundFile from '../../assets/sounds/magazine_opening_sound.mp3';

const headerBox: SxProps<Theme> = {
  minHeight: '74px',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  top: '2vh',
  left: '3vh',
};

const yellowText: SxProps<Theme> = {
  fontFamily: 'MacondoRegular',
  fontWeight: '400',
  fontSize: '3vh',
  lineHeight: '16px',
  fontStyle: 'normal',
  color: '#FFFFFF',
};

const owlText: SxProps<Theme> = {
  fontFamily: 'MacondoRegular',
  fontWeight: '400',
  fontSize: '3vh',
  lineHeight: '16px',
  fontStyle: 'normal',
  color: '#FFFFFF',
  marginLeft: '7px',
};

const imgStyle = { width: '50px', height: '51px', marginLeft: '7px' };

const issueCoverImageWrapper: SxProps<Theme> = {
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
};

export type CoverPageComponentProps = {
  magazine: Magazine;
  onCoverClick: (magazineId: number) => void;
};

export const CoverPage: React.FC<CoverPageComponentProps> = ({ magazine, onCoverClick }) => {
  const bookCoverRef = useRef<HTMLImageElement | null>(null);
  const magazineOpeningSound = new Audio(magazineOpeningSoundFile);

  const playSound = () => {
    magazineOpeningSound.play();
  };

  const handleBookCoverClick = () => {
    if (bookCoverRef.current) {
      bookCoverRef.current.classList.add('flippedBookCover');
      playSound();
      setTimeout(() => {
        onCoverClick(magazine.id);
      }, 800);
    }
  };

  return (
    <Box
      sx={{
        background: `url(${curevLine}), linear-gradient(to right,#3C3077 0%,#3C3077 50%, #FBEC53 50%, #FBEC53 100%)`,
        height: '100vh',
      }}
    >
      <Grid container>
        <Grid item xs={5.5}>
          <Box sx={headerBox}>
            <Typography sx={yellowText} variant='body2'>
              Yellow
            </Typography>
            <img src={owlHeader} style={imgStyle} alt='yellow-owl-header' />
            <Typography sx={owlText} variant='body2'>
              Owl
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box sx={issueCoverImageWrapper} onClick={handleBookCoverClick}>
            <img
              ref={bookCoverRef}
              src={magazine.coverPicUrl}
              className='bookCover'
              onClick={handleBookCoverClick}
              alt={magazine.coverPicUrl}
            />
            <div className='bookCoverBack'>
              <img src={magazine.coverPicUrl} className='bookCover2' alt={magazine.coverPicUrl} />
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
