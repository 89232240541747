import { BookComponent } from '../common/BookComponent';
import { Box, Grid, SxProps, Theme, Typography } from '@mui/material';
import { yellowOwlMessages } from '../../Constants';
import thumbsUp from '../../assets/images/thumbs-up-icon.svg';
import thumbsDown from '../../assets/images/thumbs-down-icon.svg';
import { ToastContainer, toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { MobileInformataionPage } from '../common/MobileInformationPage';

const leftPageContent: SxProps<Theme> = {
  fontWeight: '600',
  fontSize: '3vh',
  fontFamily: 'CormorantGaramondRegular',
  textAlign: 'center',
  lineHeight: '15vh',
};
const rightPageContent: SxProps<Theme> = {
  fontWeight: '600',
  fontSize: '3vh',
  fontFamily: 'CormorantGaramondRegular',
  textAlign: 'center',
};
const textContent: SxProps<Theme> = {
  fontWeight: '600',
  fontSize: '2.5vh',
  fontFamily: 'CormorantGaramondRegular',
  textAlign: 'center',
  lineHeight: '4vh',
};
const content: SxProps<Theme> = {
  fontWeight: '600',
  fontSize: '2.5vh',
  fontFamily: 'CormorantGaramondRegular',
  textAlign: 'center',
  lineHeight: '4vh',
  cursor: 'pointer',
};

const rightPaperGrid: SxProps<Theme> = {
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
};

const rightPaperDesign: SxProps<Theme> = {
  clipPath: 'polygon(100% 0, 100% 100%, 0 100%, 0 2%)',
  width: '43vw',
  height: '95vh',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
};

export const ContactUsPage = () => {
  const handleYesClick = () => {
    toast('Thanks for your feedback');
  };

  const handleNoClick = () => {
    toast('Sorry to hear that, will strongly consider your feedback');
  };

  <ToastContainer position='top-center' autoClose={3000} hideProgressBar={true} closeButton={false} />;

  const boxLikeButton = (
    <>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <img style={{ width: '5vw', cursor: 'pointer' }} src={thumbsUp} alt={'playstore'} onClick={handleYesClick} />
        <Typography sx={content} color={'#029608'} onClick={handleYesClick}>
          {yellowOwlMessages.yes}
        </Typography>
      </Box>
    </>
  );

  const boxDislikeButton = (
    <>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <img style={{ width: '5vw', cursor: 'pointer' }} src={thumbsDown} alt={'appStore'} onClick={handleNoClick} />
        <Typography sx={content} color={'#960202'} onClick={handleNoClick}>
          {yellowOwlMessages.no}
        </Typography>
      </Box>
    </>
  );

  const mobileContent = (
    <>
      <Typography align={'center'} gutterBottom sx={leftPageContent} variant={'subtitle2'}>
        {yellowOwlMessages.commentsAndQuestions}
      </Typography>
      <Typography align={'center'} gutterBottom sx={textContent} variant={'subtitle2'}>
        {yellowOwlMessages.writeToUsAt}
        <br></br>
        <u>{yellowOwlMessages.email}</u>
        <br></br>
        <br></br>
        {yellowOwlMessages.callUsAt}
        <br></br>
        {yellowOwlMessages.phoneNumber}
      </Typography>
      <br />
      <Typography sx={rightPageContent}>
        {yellowOwlMessages.writeToUsAt}
        <br></br>
        {yellowOwlMessages.yellowOwlToOthers}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '20px' }}>
        <Box>{boxLikeButton}</Box>
        <Box sx={{ ml: '20px' }}>{boxDislikeButton}</Box>
      </Box>
    </>
  );

  const leftContent = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography align={'center'} gutterBottom sx={leftPageContent} variant={'subtitle2'}>
          {yellowOwlMessages.commentsAndQuestions}
        </Typography>
        <Typography align={'center'} gutterBottom sx={textContent} variant={'subtitle2'}>
          {yellowOwlMessages.writeToUsAt}
          <br></br>
          <u>{yellowOwlMessages.email}</u>
          <br></br>
          <br></br>
          {yellowOwlMessages.callUsAt}
          <br></br>
          {yellowOwlMessages.phoneNumber}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={leftPageContent}></Grid>
    </Grid>
  );

  const rightContent = (
    <Grid item xs={12}>
      <Box sx={rightPaperGrid}>
        <Box sx={rightPaperDesign}>
          <Box sx={{ width: '35vw' }}>
            <Box sx={{ width: '35vw' }}>
              <Typography sx={rightPageContent}>
                {yellowOwlMessages.wouldYouRecommend}
                <br></br>
                {yellowOwlMessages.yellowOwlToOthers}
              </Typography>
              <Grid container spacing={7} justifyContent='center' alignItems='center' marginTop='2vh'>
                <Grid item>{boxLikeButton}</Grid>
                <Grid item>{boxDislikeButton}</Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );

  return (
    <>
      {!isMobile ? (
        <BookComponent leftContent={leftContent} rightContent={rightContent} hideButtons={false} />
      ) : (
        <MobileInformataionPage content={mobileContent} />
      )}
    </>
  );
};
