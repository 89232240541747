export const LSUserKey = 'user';
export const LSBasicUserDetailsKey = 'userDetails';
export const LSAuthDetails = 'authDetails';
export const LSInputContents = 'inputContents';

export function updateLocalStorage<T>(key: string, value: T | null, isObject?: boolean) {
  if (value) {
    if (isObject) {
      localStorage.setItem(`${key}`, JSON.stringify(value));
    } else {
      localStorage.setItem(`${key}`, value as string);
    }
  } else {
    localStorage.removeItem(`${key}`);
  }
}

export function getFromLocalStorage<T>(key: string, isObject: boolean) {
  const data = localStorage.getItem(`${key}`);
  if (isObject) {
    if (data) {
      return JSON.parse(data) as T;
    } else {
      return null;
    }
  }
  return data;
}
