import { ArrowForward } from '@mui/icons-material';
import { Box, IconButton, Theme, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import YellowOwlIcon from '../../assets/icons/owl-landing-page.svg';
import YellowOwlTextIcon from '../../assets/icons/owl-tilte.svg';
import LargeCurve from '../../assets/images/large-curve.svg';
import laptop from '../../assets/icons/laptop.svg';
import rocket from '../../assets/icons/rocket.svg';
import { SxProps } from '@mui/system';
import { yellowOwlMessages } from '../../Constants';
import { isMobile } from 'react-device-detect';

const container = {
  width: '100vw',
  height: '100vh',
  background: `url(${LargeCurve}) #3C3077`,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  boxSizing: 'border-box',
  overflowY: 'auto',
};

const titleText = {
  fontSize: '2rem',
  lineHeight: '1rem',
  fontWeight: '700',
  paddingTop: '2.5rem',
  color: '#FFFFFF',
  fontStyle: 'normal',
};

const owlImage = {
  height: '60%',
  paddingTop: '2.5rem',
  paddingBottom: '2.75rem',
};

const forwardIcon = {
  borderRadius: '0.25rem',
  background: '#FBEC53',
  height: '4rem',
  width: '4rem',
  ':hover': {
    backgroundColor: '#FBEC53',
  },
};

const footerBox = {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  height: '5%',
  paddingRight: '4rem',
  gap: '1.5rem',
  boxSizing: 'border-box',
};

// const textPrimary = {
//   color: '#FFFFFF',
// };

const laptopIcon: SxProps<Theme> = {
  img: {
    width: '5vw',
  },
  position: 'absolute',
  top: '5vh',
  left: '5vw',
};

const rocketIcon: SxProps<Theme> = {
  img: {
    width: '5vw',
  },
  position: 'absolute',
  bottom: '5vh',
  left: '4vw',
};

const titleContainer = {
  display: 'flex',
  flexDirection: 'column',
  height: '20%',
};
const owlIconContainer = {
  display: 'flex',
  flexDirection: 'column',
  height: '75%',
  alignItems: 'center',
};

export const LandingPage = () => {
  const navigate = useNavigate();

  const handleNextClick = () => {
    navigate('/otp-page');
  };
  const handlePrivacyPolicyClick = () => {
    navigate('/privacy-policy');
  };

  const handleNoteToParentClick = () => {
    navigate('/note-to-parent');
  };

  const handleContactUsClick = () => {
    navigate('/contact-us');
  };
  return (
    <Box sx={container}>
      <Box sx={laptopIcon}>
        <img src={laptop} alt='laptop' />
      </Box>
      <Box sx={rocketIcon}>
        <img src={rocket} alt='rocket' />
      </Box>
      <Box sx={titleContainer}>
        <img style={{ maxHeight: '4rem' }} src={YellowOwlTextIcon} alt='yellow-owl-text-icon' />
        <Typography align='center' sx={titleText}>
          {!isMobile ? (
            `A skill-building children's magazine!`
          ) : (
            <>
              {`A skill-building`}
              <br />
              <br />
              {`children's magazine!`}
            </>
          )}
        </Typography>
      </Box>
      <Box sx={owlIconContainer}>
        <img style={owlImage} src={YellowOwlIcon} alt='yellow-owl-icon' />
        {!isMobile && (
          <IconButton sx={forwardIcon} onClick={handleNextClick}>
            <ArrowForward color='primary' />
          </IconButton>
        )}
      </Box>
      <Box sx={footerBox}>
        <Typography sx={{ color: 'white', cursor: 'pointer', fontSize: '1.5rem' }} onClick={handleNoteToParentClick}>
          {yellowOwlMessages.NOTE_TO_PARENTS}
        </Typography>
        <Typography sx={{ color: 'white', cursor: 'pointer', fontSize: '1.5rem' }} onClick={handleContactUsClick}>
          {yellowOwlMessages.CONTACT_US}
        </Typography>
        <Typography sx={{ color: 'white', cursor: 'pointer', fontSize: '1.5rem' }} onClick={handlePrivacyPolicyClick}>
          {yellowOwlMessages.PRIVACY_POLICY}
        </Typography>
      </Box>
    </Box>
  );
};
