import yellowOwlLogo from '../../assets/icons/owl-sidepanel.svg';
import laptop from '../../assets/icons/laptop.svg';
import rank from '../../assets/icons/rank.svg';
import rocket from '../../assets/icons/rocket.svg';
import football from '../../assets/icons/football.svg';
import curl from '../../assets/icons/curl.svg';
import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

const commonContent: SxProps<Theme> = {
  width: '50vw',
  height: '75vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'CormorantGaramondRegular, sans-serif',
  lineHeight: '72px',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '5vh',
  color: '#FFFFFF',
  transform: 'translateY(0%)',
};

const yellowOwlLogoBorderBox: SxProps<Theme> = {
  img: {
    width: '14vw',
    height: '14vw',
  },
  display: 'flex',
  width: '14vw',
  height: '14vw',
  background: 'linear-gradient(#3C3077, #3C3077) padding-box, linear-gradient(to top, #6649D8, #FBEC53) border-box',
  borderRadius: '50em',
  border: '19px solid transparent',
  alignItems: 'center',
  justifyContent: 'center',
};

const laptopIcon: SxProps<Theme> = {
  img: {
    width: '5vw',
  },
  position: 'absolute',
  top: '2vh',
  left: '5vw',
};

const rankIcon: SxProps<Theme> = {
  img: {
    width: '8vw',
  },
  position: 'absolute',
  top: '3vh',
  right: '8vw',
};

const rocketIcon: SxProps<Theme> = {
  img: {
    width: '5vw',
  },
  position: 'absolute',
  bottom: 0,
  left: '4vw',
};

const footballIcon: SxProps<Theme> = {
  img: {
    width: '5vw',
  },
  position: 'absolute',
  bottom: 0,
  right: 0,
};

const curlIcon: SxProps<Theme> = {
  img: {
    width: '5vw',
  },
  position: 'absolute',
  bottom: '5vh',
  right: '12vw',
};

export const LeftPanelWalkthrough: React.FC = () => {
  return (
    <Box sx={commonContent}>
      <Box sx={laptopIcon}>
        <img src={laptop} alt='laptop' />
      </Box>
      <Box sx={rankIcon}>
        <img src={rank} alt='rank' />
      </Box>
      <Box sx={rocketIcon}>
        <img src={rocket} alt='rocket' />
      </Box>
      <Box sx={footballIcon}>
        <img src={football} alt='football' />
      </Box>
      <Box sx={curlIcon}>
        <img src={curl} alt='curl' />
      </Box>
      <Box>Hiya!</Box>
      <Box sx={yellowOwlLogoBorderBox}>
        <img src={yellowOwlLogo} alt='yellowOwlLogo' />
      </Box>
    </Box>
  );
};
