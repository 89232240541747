import React, { createContext, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import { SxProps, Theme } from '@mui/material';

const arrowButtonLeftStyles: SxProps<Theme> = {
  cursor: 'pointer',
  left: '2vh',
  top: '45vh',
  position: 'absolute',
  fontSize: '10vh',
};

const arrowButtonRightStyles: SxProps<Theme> = {
  cursor: 'pointer',
  right: '2vh',
  top: '45vh',
  position: 'absolute',
  fontSize: '10vh',
};

export type NavigationPageProps = {
  totalPages: number;
  children: React.ReactElement;
};
export type NavigationPageContextType = {
  pageNos: number[];
};
export const NavigationPageContext = createContext<NavigationPageContextType>({ pageNos: [1, 2] });
export const NavigationPage: React.FC<NavigationPageProps> = ({ totalPages, children }) => {
  const [context, setContext] = useState<NavigationPageContextType>({ pageNos: [1, 2] });

  const onClickForward = () => {
    setContext((prevState) => {
      return { pageNos: [prevState.pageNos[0] + 2, prevState.pageNos[1] + 2] };
    });
  };

  const onClickBack = () => {
    setContext((prevState) => {
      return { pageNos: [prevState.pageNos[0] - 2, prevState.pageNos[1] - 2] };
    });
  };

  return (
    <NavigationPageContext.Provider value={context}>
      {!context.pageNos.includes(totalPages) && (
        <ArrowForwardIosIcon onClick={onClickForward} sx={arrowButtonRightStyles} color={'disabled'} />
      )}
      {!context.pageNos.includes(1) && (
        <ArrowBackIos onClick={onClickBack} sx={arrowButtonLeftStyles} color={'disabled'} />
      )}
      {children}
    </NavigationPageContext.Provider>
  );
};
