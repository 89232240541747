import { useState, useEffect } from 'react';

export type UseCountDownTimerProps = {
  timeInMinutes: number;
};

export const useCountDownTimer = ({ timeInMinutes }: UseCountDownTimerProps) => {
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentTime === timeInMinutes * 60) {
        clearInterval(interval);
      } else {
        setCurrentTime(currentTime + 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [currentTime, timeInMinutes]);

  const handleRestartTimer = () => {
    setCurrentTime(0);
  };

  return {
    minutes: Math.floor(timeInMinutes - currentTime / 60),
    seconds: currentTime % 60 === 0 ? 0 : 60 - (currentTime % 60),
    restartTimer: handleRestartTimer,
  };
};
