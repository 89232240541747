import { Box, Paper, TextField, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { ActivitySkillType, InputContent, QuestionFormat } from '@yellow-owl/client-sdk';
import { useAsyncEffect } from '../../../hooks/use-async';
import { YellowOwlContext } from '../../../context/YellowOwlContext';
import { useDebounce } from 'use-debounce';

export type ActivityType = 'StoryTime' | 'ThinkOutside' | 'LookWithin' | 'SpinAStory' | 'DeepDive' | 'AllEars';

export type MagazineTextAreaProps = {
  questionFormat: QuestionFormat;
  activityId: string;
  magazineId: string;
  userId: string;
  key: string;
  rows: number;
  placeholder: string;
  inputContent: Array<InputContent>;
  questionId: number;
  questionFormatId: string;
  tenantId: number;
  categoryId: number;
  skill: Array<ActivitySkillType>;
  globalInputState: boolean;
  value?: string;
  sx?: SxProps<Theme>;
  label?: string;
  onChangeInput?: (event: ChangeEvent<HTMLInputElement>) => void;
  onUpdateInputContents: (input: Array<InputContent>) => boolean;
};

function MagazineTextArea(props: MagazineTextAreaProps) {
  const {
    userId,
    magazineId,
    rows,
    placeholder,
    value,
    sx,
    activityId,
    inputContent,
    questionId,
    questionFormat,
    questionFormatId,
    tenantId,
    categoryId,
    skill,
    globalInputState,
    onUpdateInputContents,
  } = props;

  const yellowOwlApi = useContext(YellowOwlContext)!;

  const [data, setData] = useState<string | undefined>(value ? value : undefined);
  const [isValid, setIsValid] = useState<boolean>(true);

  const [textChange] = useDebounce(data, 1000);

  // TODO: Naveed - move dynamic styles to a function and pass values from here and get it back

  const textFieldBoxStyles: SxProps<Theme> = {
    width: '95%',
    borderRadius: '12px',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    border: isValid ? '3px solid #3C3077' : '4px solid red',
    boxShadow: '1px 2px 6px 3px #b2acd3',
  };

  const textFieldStyles: SxProps<Theme> = {
    ...sx,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        border: 'none',
      },
      backgroundColor: data?.trim() && data?.trim().length > 0 ? 'rgba(251, 236, 83, 0.5)' : 'inherit',
      boxShadow: data?.trim() && data?.trim().length > 0 ? 'rgba(251, 236, 83, 0.5)' : 'inherit',
      border: 'none',
      borderRadius: '15px',
      padding: '10px',
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    width: '100%',
    textarea: {
      fontFamily: 'RalewayRegular',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '1.7vh',
      letterSpacing: '0.1vw',
      width: '100%',
      marginTop: '7px',
      border: 'none',
    },
  };

  const handleChange = (answer: string) => {
    inputContent.forEach((inputElt: InputContent) => {
      inputElt.questionData.forEach((questionData) => {
        if (questionData.id === +questionId) {
          questionData.questionFormat.forEach((questionFormat) => {
            if (questionFormat.format.id === +questionFormatId) {
              questionFormat.format.response = answer;
            }
          });
        }
      });
    });

    setData(answer);
    if (questionFormat.format.mandatory) {
      setIsValid(answer.trim().length > 0);
    } else {
      setIsValid(true);
    }
  };

  useAsyncEffect({
    fn: async () => {
      if (textChange !== null && inputContent.length > 0) {
        const result = await yellowOwlApi.addUserResponse({
          userId: +userId,
          magazineId: +magazineId,
          activityId: activityId,
          inputContent: inputContent,
          tenantId: +tenantId,
          categoryId: +categoryId,
          skill: skill,
        });
        if (result) {
          onUpdateInputContents(inputContent);
        }
        return result.data;
      }
    },
    dependencies: [textChange],
  });

  useEffect(() => {
    if (globalInputState) {
      let isValid = false;

      inputContent.forEach((inputElt) => {
        inputElt.questionData.forEach((questionData) => {
          if (questionData.id === +questionId) {
            questionData.questionFormat.forEach((questionFormat) => {
              if (questionFormat.format.id === +questionFormatId) {
                const mandatory = questionFormat.format.mandatory ?? false;
                const response = questionFormat.format.response;

                if (mandatory) {
                  if (typeof response === 'string') {
                    isValid = response.trim() !== '';
                  } else if (Array.isArray(response)) {
                    isValid = response.every((res) => res.trim() !== '');
                  }
                } else {
                  isValid = !!response;
                }
              }
            });
          }
        });
      });

      setIsValid(isValid);
    }
  }, [globalInputState, inputContent, questionId, questionFormatId]);

  return (
    <Box sx={{ width: '100%', padding: '10px' }}>
      <Paper sx={textFieldBoxStyles} elevation={0}>
        <TextField
          sx={textFieldStyles}
          placeholder={placeholder}
          onChange={(e) => handleChange(e.target.value)}
          value={data}
          multiline
          rows={rows}
        />
      </Paper>
    </Box>
  );
}

export default MagazineTextArea;
