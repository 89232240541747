import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box } from '@mui/material';
import { YellowOwlContext } from '../../../context/YellowOwlContext';
import React, { useContext } from 'react';
const publishDialogStyle = {
  borderRadius: '20px',
  boxShadow: '0px 0px 15px #3c3075',
  border: '4px solid #3c3075',
  background: 'linear-gradient(to bottom, #ede7f6, #E6DAFC)',
  margin: 0,
  padding: '20px',
};

const publishDialogTitleStyle = {
  padding: 0,
};

const publishTitleBoxStyle = {
  fontFamily: 'CormorantGaramondRegular',
  fontWeight: 'bold',
  color: '#ffffff',
  fontSize: '24px',
  padding: '10px 0',
  paddingLeft: '20px',
  backgroundColor: '#3c3075',
  borderRadius: '15px 15px 0 0',
  marginBottom: '12px',
};

const publishDialogContentStyle = {
  padding: 0,
};

const publishContentBoxStyle = {
  textAlign: 'center',
  fontWeight: 'bold',
  fontFamily: 'CormorantGaramondRegular',
  color: '#3c3075',
  fontSize: '18px',
  padding: '20px',
  backgroundColor: '#ede7f6',
};

const publishDialogActionsStyle = {
  justifyContent: 'center',
  gap: 3,
  padding: 2,
  backgroundColor: '#ede7f6',
  borderRadius: '0 0 15px 15px',
};

const publishButtonStyle = {
  border: '3px solid #3c3075',
  color: 'white',
  backgroundColor: '#3c3075',
  fontFamily: 'CormorantGaramondRegular',
  borderRadius: '4px',
  boxShadow: 'none',
  fontWeight: 'bold',
  fontSize: '16px',
  padding: '8px 16px',
  '&:hover': {
    backgroundColor: '#3c3075',
    color: 'white',
    boxShadow: 8,
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
};

type PublishModalType = {
  title: string;
  content: string;
  open: boolean;
  publishContent: any;
  onClose: (status: boolean, message: string) => void;
};

export default function PublishModal(props: PublishModalType) {
  const { title, content, open, publishContent, onClose } = props;
  const yellowOwlApi = useContext(YellowOwlContext)!;

  const handleCancel = () => {
    onClose(false, 'Publish cancelled');
  };

  const handleOk = async () => {
    const result = await saveCommunityWallPost();
    const { status, message } = result;
    onClose(status, message);
  };

  const saveCommunityWallPost = async () => {
    if (!publishContent) {
      return { status: false, message: `Can't Publish this Post.` };
    }
    try {
      const result = await yellowOwlApi.saveCommunityWallPost(publishContent);
      return {
        status: true,
        message: result?.data?.message || 'Post published successfully.',
      };
    } catch (error) {
      console.error('Error saving community wall post:', error);
      return {
        status: false,
        message: 'Something went wrong. Try to publish again later.',
      };
    }
  };

  return (
    <Dialog keepMounted open={open} PaperProps={{ sx: publishDialogStyle }}>
      <Box>
        <DialogTitle sx={publishDialogTitleStyle}>
          <Box sx={publishTitleBoxStyle}>{title}</Box>
        </DialogTitle>
        <DialogContent sx={publishDialogContentStyle}>
          <Box sx={publishContentBoxStyle}>{content}</Box>
        </DialogContent>
        <DialogActions sx={publishDialogActionsStyle}>
          <Button autoFocus onClick={handleCancel} disableElevation sx={publishButtonStyle}>
            No
          </Button>
          <Button onClick={handleOk} disableElevation sx={publishButtonStyle}>
            Yes
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
