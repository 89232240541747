import { useContext, useEffect, useState } from 'react';
import { YellowOwlContext } from '../../context/YellowOwlContext';
import { useLocalStorage } from '../../context/LocalStorageContext';
import { LeaderBoardUserItem } from '@yellow-owl/client-sdk/src/api';
import { useAsyncEffect } from '../../hooks/use-async';
import { Leaderboard } from '../../components/leaderboard/LeaderboardPage';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Typography, SxProps, Theme } from '@mui/material';
import dayjs from 'dayjs';

const containerStyles: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
};

const loaderStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
};

const errorIconStyles: SxProps<Theme> = {
  color: 'error.main',
  fontSize: 'large',
};

const errorTextStyles: SxProps<Theme> = {
  color: 'error.main',
  variant: 'h6',
};

const getDateRange = (filter: string) => {
  const today = dayjs();
  switch (filter) {
    case 'today':
      return { startDate: today.startOf('day').toISOString(), endDate: today.endOf('day').toISOString() };
    case 'thisWeek':
      return { startDate: today.startOf('week').toISOString(), endDate: today.endOf('week').toISOString() };
    case 'thisMonth':
      return { startDate: today.startOf('month').toISOString(), endDate: today.endOf('month').toISOString() };
    case 'all':
      return { startDate: null, endDate: null };
    default:
      return { startDate: today.startOf('week').toISOString(), endDate: today.endOf('week').toISOString() };
  }
};

export const LeaderboardContainer: React.FC = () => {
  const yellowOwlApi = useContext(YellowOwlContext)!;
  const { state } = useLocalStorage();
  const [selectedFilter, setSelectedFilter] = useState<string>('thisMonth');
  const [selectedGrade, setSelectedGrade] = useState<string>('all');
  const [leaderBoardData, setLeaderBoardData] = useState<LeaderBoardUserItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [leaderboardError, setLeaderboardError] = useState<boolean>(false);

  const { startDate, endDate } = getDateRange(selectedFilter);

  const queryParams = {
    tenantId: state.tenantId || '1',
    grade: selectedGrade || undefined,
    ...(startDate && endDate ? { startDate, endDate } : {}),
  };

  const handleFilterChange = (newFilter: string) => {
    if (newFilter !== selectedFilter) {
      setSelectedFilter(newFilter);
      setIsLoading(true);
    }
  };

  const handleGradeChange = (newGrade: string) => {
    if (newGrade !== selectedGrade) {
      setSelectedGrade(newGrade);
      setIsLoading(true);
    }
  };

  const [leaderBoardDataState] = useAsyncEffect<LeaderBoardUserItem[]>({
    fn: async () => {
      try {
        const startDate = queryParams.startDate ?? '';
        const endDate = queryParams.endDate ?? '';
        const result = await yellowOwlApi.getLeaderboardForTenant(
          queryParams.tenantId,
          startDate,
          endDate,
          queryParams.grade
        );
        return result?.data?.response;
      } catch (error) {
        setLeaderboardError(true);
        return [];
      }
    },
    dependencies: [queryParams.tenantId, queryParams.startDate, queryParams.endDate, queryParams.grade],
  });

  useEffect(() => {
    if (!leaderBoardDataState.isLoading) {
      setIsLoading(false);
      setIsInitialLoad(false);

      if (leaderBoardDataState.result) {
        setLeaderBoardData(leaderBoardDataState.result);
      }

      if (leaderBoardDataState.error) {
        setLeaderboardError(true);
      }
    }
  }, [leaderBoardDataState]);

  if (isLoading && isInitialLoad) {
    return (
      <Box sx={loaderStyles}>
        <CircularProgress />
      </Box>
    );
  }

  if (leaderboardError) {
    return (
      <Box sx={containerStyles}>
        <ErrorOutlineIcon sx={errorIconStyles} />
        <Typography sx={errorTextStyles}>Failed to load leaderboard. Please try again later.</Typography>
      </Box>
    );
  }

  return (
    <Leaderboard
      userData={leaderBoardData}
      setLeaderboardFilter={handleFilterChange}
      setLeaderboardGrade={handleGradeChange}
      initialFilter={selectedFilter}
      initialGrade={selectedGrade}
    />
  );
};
