import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { AuthContextProvider } from './context/Auth/AuthContext';
import { YellowOwlContext } from './context/YellowOwlContext';
import { AppRoutes } from './route/router';
import { theme } from './theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createYellowOwlClient } from './context/Auth/AuthenticatedApiClient';
import { UserProvider } from './context/User/UserContext';
import { isMobile } from 'react-device-detect';
import { LocalStorageProvider } from './context/LocalStorageContext';
import { NavigationProvider } from './context/NavigationContextType';
import MobileWarning from './components/common/MobileWarning';

// TODO: move this later to shell

function App() {
  // TODO: get this url later from env file
  const yellowOwlClient = createYellowOwlClient(process.env.REACT_APP_BACKEND_URL!);

  return (
    <ThemeProvider theme={theme}>
      {!isMobile ? (
        <AuthContextProvider>
          <UserProvider>
            <YellowOwlContext.Provider value={yellowOwlClient}>
              <LocalStorageProvider>
                <NavigationProvider>
                  <AppRoutes />
                </NavigationProvider>
                <ToastContainer position='top-right' autoClose={3000} hideProgressBar={true} closeButton={false} />
              </LocalStorageProvider>
            </YellowOwlContext.Provider>
          </UserProvider>
        </AuthContextProvider>
      ) : (
        <MobileWarning />
      )}
    </ThemeProvider>
  );
}

export default App;
