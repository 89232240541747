import { Box, SxProps, Theme } from '@mui/material';

export type MobileInformationPageProps = {
  content: React.ReactElement;
};

const outerContainerStyle: SxProps<Theme> = {
  display: 'flex',
  width: '100%',
  height: '100vh',
  backgroundColor: 'yellow',
};

const innerContainerStyle: SxProps<Theme> = {
  flex: 1,
  margin: '8%',
  border: '1px solid white',
  borderRadius: '10px',
  backgroundColor: 'white',
  flexDirection: 'column',
  overflowY: 'scroll',
};

export const MobileInformataionPage: React.FC<MobileInformationPageProps> = ({ content }) => {
  return (
    <Box sx={outerContainerStyle}>
      <Box sx={innerContainerStyle}>{content}</Box>
    </Box>
  );
};
