/* eslint-disable */

import { useEffect, useReducer, useState } from 'react';

import { AxiosError } from 'axios';
import { useDeepCompareEffect } from 'react-use';

// actions

export type StartAction = {
  type: 'start';
};

export type FinishAction<T> = {
  type: 'finish';
  value: T;
};

export type ErrorAction = {
  type: 'error';
  error: any;
};

// dispatch types

export type DispatchTypes<T> = StartAction | FinishAction<T> | ErrorAction;

// async state

export type AsyncState<T> = {
  isLoading: boolean;
  isComplete: boolean;
  result?: T;
  error?: any;
};

// async return

export type AsyncReturn<T> = [state: AsyncState<T>, run: (args?: any) => Promise<void>, setState: (value: T) => void];

// use async hook

export type UseAsyncProps<T> = {
  fn: (args: any) => Promise<T>;
};

export function useAsync<T>({ fn }: UseAsyncProps<T>): AsyncReturn<T> {
  const initialState = { isLoading: false, hasError: false, value: undefined, isComplete: false };

  const stateReducer = (_state: AsyncState<T>, action: DispatchTypes<T>): AsyncState<T> => {
    switch (action.type) {
      case 'start':
        return { isLoading: true, isComplete: false } as AsyncState<T>;
      case 'finish':
        return { isLoading: false, result: action.value, isComplete: true } as AsyncState<T>;
      case 'error':
        return { isLoading: false, error: action.error, isComplete: true } as AsyncState<T>;
      default:
        return { isLoading: true, isComplete: false } as AsyncState<T>;
    }
  };

  const [state, dispatch] = useReducer(stateReducer, initialState);

  const setState = (value: T) => {
    dispatch({ type: 'finish', value });
  };

  const run = async (args: any) => {
    try {
      dispatch({ type: 'start' });
      const value = await fn(args);
      dispatch({ type: 'finish', value });
    } catch (error) {
      dispatch({
        type: 'error',
        error: (error as AxiosError).response?.data as any,
      });
    }
  };

  return [{ ...state }, run, setState];
}

// use async effect params

export type UseAsyncEffectParams<T> = {
  fn: (args: any) => Promise<T>;
  dependencies: any[];
  useDeepCompare?: boolean;
  triggerCondition?: boolean;
};

// use async effect hook

export function useAsyncEffect<T>({
  fn,
  dependencies,
  useDeepCompare = false,
  triggerCondition = true,
}: UseAsyncEffectParams<T>): AsyncReturn<T> {
  const [state, run, setState] = useAsync({ fn });

  if (useDeepCompare) {
    useDeepCompareEffect(() => {
      if (triggerCondition) run();
    }, dependencies);
  } else {
    useEffect(() => {
      if (triggerCondition) run();
    }, dependencies);
  }

  return [state, run, setState];
}

// useAsyncEffectOnce hook

export function useAsyncEffectOnce<T>({
  fn,
  dependencies,
  useDeepCompare = false,
  triggerCondition = true,
}: UseAsyncEffectParams<T>): AsyncReturn<T> {
  const [state, run, setState] = useAsync({ fn });
  const [calledOnce, setCalledOnce] = useState(false);

  const canTrigger = triggerCondition && !calledOnce;

  if (useDeepCompare) {
    useDeepCompareEffect(() => {
      if (canTrigger) {
        run();
        setCalledOnce(true);
      }
    }, dependencies);
  } else {
    useEffect(() => {
      if (canTrigger) {
        run();
        setCalledOnce(true);
      }
    }, dependencies);
  }

  return [state, run, setState];
}
