import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, IconButton, CircularProgress, Box, Typography, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAsync } from '../../../hooks/use-async';
import { YellowOwlContext } from '../../../context/YellowOwlContext';
import { yellowOwlMessages } from '../../../Constants';
import ConfettiExplosion from 'react-confetti-explosion';
import { CSSProperties } from '@mui/material/styles/createTypography';
import rewards_gif from '../../../assets/gif/reward_animations.gif';

const feedbackStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '12px',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  border: '5px solid #3C3077',
  boxShadow: '1px 2px 6px 3px #b2acd3',
  p: 4,
  fontFamily: 'Schoolbell',
  fontWeight: '500',
};

const feedbackHeader = {
  fontFamily: 'Schoolbell',
  fontWeight: '900',
  fontSize: '1.7rem',
  margin: '10px 5px',
};

const ratingStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  span: {
    background: 'white',
    cursor: 'pointer',
    margin: '10px 0px',
    width: '80px',
    height: '80px',
    fontSize: '4rem',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    border: '3px solid #3C3077',
    boxShadow: '1px 2px 6px 3px #b2acd3',
  },
  p: {
    fontSize: '1.2rem',
    fontWeight: '700',
    marginTop: '10px',
  },
};

const emojiStyles = {
  width: '31%',
  height: '150px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
};

const iamDoneStyles = {
  fontSize: '20px',
  border: '4px solid #3C3077',
  borderRadius: '16px',
};

const rewardsPopupStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '400px',
  maxWidth: '600px',
  bgcolor: 'background.paper',
  borderRadius: '12px',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  border: '5px solid #3C3077',
  boxShadow: '1px 2px 6px 3px #b2acd3',
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const confettiWrapperStyle: CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  pointerEvents: 'none',
  zIndex: 9999,
};

const badgeDesc = {
  fontWeight: 700,
  fontFamily: 'Cormorant Garamond',
  color: 'primary.main',
  border: '2px solid #3C3077',
  boxShadow: '1px 2px 4px 2px #b2acd3',
  borderRadius: '8px',
  padding: '3px 6px',
  width: '100%',
  maxWidth: '200px',
  overflowWrap: 'break-word',
  textAlign: 'center',
  fontSize: '1.2rem',
};

function MagazineRating(props: MagazineRatingProps) {
  const {
    userId,
    magazineId,
    activityId,
    tenantId,
    categoryId,
    canShowFeedbackModal,
    onCheckMandatory,
    onCloseFeedbackSubmitModal,
  } = props;

  const [ratingData, setRatingData] = useState<number | undefined>(undefined);
  const [showAnimation, setShowAnimation] = useState(false);
  const [showRewardsPopup, setShowRewardsPopup] = useState(false);
  const [showBadgesPopup, setShowBadgesPopup] = useState(false);
  const [rewards, setRewards] = useState<any>(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(canShowFeedbackModal);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const yellowOwlApi = useContext(YellowOwlContext)!;

  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };
  const [showConfetti, setShowConfetti] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const hasShownSuccessToast = useRef(false);
  const hasShownErrorToast = useRef(false);

  const queryParams = getQueryParams(location.search);
  const id = queryParams.get('id');

  const navigateToTableOfContents = () => {
    navigate({
      pathname: '/magazine/index',
      search: `?id=${id}`,
    });
  };

  const handleClose = () => {
    setShowFeedbackModal(false);
    onCloseFeedbackSubmitModal();
  };

  const handleEmojiClick = (newRating: number) => {
    setRatingData(newRating);
    setIsWaitingForResponse(true);
  };

  const [addUserFeedbackResult, addUserFeedback] = useAsync({
    fn: async () => {
      if (ratingData && ratingData > 0) {
        const result = await yellowOwlApi.addUserFeedback({
          userId: +userId,
          magazineId: +magazineId,
          activityId: +activityId,
          rating: ratingData as any,
          tenantId: tenantId,
          categoryId: categoryId,
          deviceType: 'web',
        });
        return result.data;
      }
    },
  });

  const [, addUserTimeSpent] = useAsync({
    fn: async () => {
      const key = `${userId}_${magazineId}_${activityId}`;
      const storedTimeSpent = localStorage.getItem('timeSpent');
      const timeSpentData = storedTimeSpent ? JSON.parse(storedTimeSpent) : {};
      const userTimeSpent = timeSpentData[key] ? timeSpentData[key] : 0;

      const result = await yellowOwlApi.addUserTimeSpent({
        userId: +userId,
        magazineId: +magazineId,
        activityId: +activityId,
        tenantId: tenantId,
        categoryId: categoryId,
        timeSpent: +userTimeSpent,
      });
      return result.data;
    },
  });

  useEffect(() => {
    setShowFeedbackModal(canShowFeedbackModal);
  }, [canShowFeedbackModal]);

  useEffect(() => {
    if (ratingData && ratingData > 0) {
      addUserFeedback();
      addUserTimeSpent();
    }
  }, [ratingData]);

  useEffect(() => {
    if (addUserFeedbackResult.result?.message && !hasShownSuccessToast.current) {
      toast.success('Thanks for your feedback');
      setRewards(addUserFeedbackResult.result.userRewardsAndBadges);
      hasShownSuccessToast.current = true;
      setIsWaitingForResponse(false);
      setShowAnimation(true);
    }
  }, [addUserFeedbackResult.result]);

  useEffect(() => {
    if (addUserFeedbackResult.error && !hasShownErrorToast.current) {
      toast.error('An error occurred while submitting feedback');
      handleClose();
      hasShownErrorToast.current = true;
      setIsWaitingForResponse(false);
    }
  }, [addUserFeedbackResult.error]);

  useEffect(() => {
    if (showAnimation) {
      const timer = setTimeout(() => {
        setShowAnimation(false);
        setShowFeedbackModal(false);
        if (rewards?.rewards && rewards.rewards.length > 0) {
          setShowRewardsPopup(true);
        } else if (rewards?.badges && rewards.badges.length > 0) {
          setShowBadgesPopup(true);
        } else {
          handleClose();
          navigateToTableOfContents();
        }
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [showAnimation, rewards]);

  const handleRewardsOkClick = () => {
    setShowRewardsPopup(false);
    setShowConfetti(true);

    setTimeout(() => {
      setShowConfetti(false);

      if (rewards?.badges && rewards.badges.length > 0) {
        setShowBadgesPopup(true);
      } else {
        handleClose();
        navigateToTableOfContents();
      }
    }, 3000);
  };

  const handleBadgesOkClick = () => {
    setShowBadgesPopup(false);
    setShowConfetti(true);

    setTimeout(() => {
      setShowConfetti(false);
      handleClose();
      navigateToTableOfContents();
    }, 3000);
  };

  const userRewards = rewards?.rewards;

  userRewards?.forEach((obj: any) => console.log(obj));

  const totalScore = userRewards?.reduce((acc: any, obj: any) => acc + obj.score, 0);

  const emojis = [
    { rating: 1, emoji: '😑', label: yellowOwlMessages.ITS_OKAY },
    { rating: 2, emoji: '🙂', label: yellowOwlMessages.ITS_GOOD },
    { rating: 3, emoji: '🥳', label: yellowOwlMessages.AMAZING },
  ];

  return (
    <div>
      <Button onClick={onCheckMandatory} variant='outlined' sx={iamDoneStyles}>
        {yellowOwlMessages.IAM_DONE}
      </Button>
      <Modal
        open={showFeedbackModal}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={feedbackStyle}>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
            <CloseIcon />
          </IconButton>
          <Typography variant='h5' sx={feedbackHeader}>
            {yellowOwlMessages.HOW_DID_YOU_LIKE_THE_ACTIVITY}
          </Typography>
          {isWaitingForResponse ? (
            <Box display='flex' justifyContent='center' alignItems='center' minHeight='150px'>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={ratingStyles}>
              {emojis.map(({ rating, emoji, label }) => (
                <Box key={rating} sx={emojiStyles} onClick={() => handleEmojiClick(rating)}>
                  <span>{emoji}</span>
                  <p>{label}</p>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Modal>

      {showConfetti && (
        <div style={confettiWrapperStyle}>
          <ConfettiExplosion force={0.8} duration={3000} particleCount={500} width={1600} height={800} />
        </div>
      )}

      {/* Rewards Popup */}
      <Modal
        open={showRewardsPopup}
        onClose={() => setShowRewardsPopup(false)}
        aria-labelledby='rewards-popup-title'
        aria-describedby='rewards-popup-description'
      >
        <Box sx={rewardsPopupStyle}>
          <Typography id='rewards-popup-title' variant='h5' component='h2' gutterBottom sx={feedbackHeader}>
            {yellowOwlMessages.CONGRATULATIONS}
          </Typography>
          {rewards?.rewards.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                maxWidth: 300,
                mx: 'auto',
                p: 2,
                bgcolor: 'background.paper',
                borderRadius: 2,
              }}
            >
              <img
                src={rewards_gif}
                alt='reward'
                style={{ width: 200, height: 200, marginRight: 15, marginBottom: 20 }}
              />

              <Typography variant='h6' sx={badgeDesc}>
                Yay ! That s {totalScore} points!
              </Typography>
            </Box>
          )}
          <Button onClick={handleRewardsOkClick} variant='contained' sx={{ mt: 2 }}>
            OK
          </Button>
        </Box>
      </Modal>

      {/* Badges Popup */}
      <Modal
        open={showBadgesPopup}
        onClose={() => setShowBadgesPopup(false)}
        aria-labelledby='badges-popup-title'
        aria-describedby='badges-popup-description'
      >
        <Box sx={rewardsPopupStyle}>
          <Typography id='rewards-popup-title' variant='h5' component='h2' gutterBottom sx={feedbackHeader}>
            {yellowOwlMessages.CONGRATULATIONS}
          </Typography>

          <Box
            display='flex'
            flexDirection='row'
            flexWrap='wrap'
            justifyContent='center'
            alignItems='center'
            sx={{
              width: '100%',
              minWidth: '300px',
              gap: 2, // Adds spacing between badges in a row
              overflowX: 'hidden',
            }}
          >
            {rewards?.badges.map((badge: any, index: number) => (
              <Box
                key={index}
                display='flex'
                flexDirection='column'
                alignItems='center'
                m={1}
                sx={{
                  width: 'auto',
                  maxWidth: 150,
                  textAlign: 'center',
                }}
              >
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  sx={{
                    width: 100,
                    height: 100,
                    marginBottom: 1,
                  }}
                >
                  <img
                    src={badge.badgeUrl}
                    alt={badge.badgeType}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </Box>
                <Typography variant='caption' sx={badgeDesc}>
                  {badge.badgeType}
                </Typography>
              </Box>
            ))}
          </Box>
          <Button onClick={handleBadgesOkClick} variant='contained' sx={{ mt: 2 }}>
            OK
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

interface MagazineRatingProps {
  userId: string;
  magazineId: string;
  activityId: string;
  tenantId: number;
  categoryId: number;
  canShowFeedbackModal: boolean;
  onCheckMandatory: () => void;
  onCloseFeedbackSubmitModal: () => void;
  rating?: 1 | 2 | 3;
}

export default MagazineRating;
