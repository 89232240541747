import {
  Box,
  Select,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  SxProps,
  Theme,
  Typography,
  SelectChangeEvent,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Top3StudentPositionCard } from './Top3StudentPositionCard';
import { IndividualLeaderboardPositionCard } from './IndividualLeaderboardPositionCard';
import { theme } from '../../theme';
import { LeaderBoardUserItem } from '@yellow-owl/client-sdk';

type LeaderboardPageProps = {
  userData: LeaderBoardUserItem[];
  setLeaderboardFilter: (newFilter: string) => void;
  setLeaderboardGrade: (newGrade: string) => void;
  initialFilter?: string;
  initialGrade?: string;
};

const leaderboardRightContainer: SxProps<Theme> = {
  width: 'auto',
  height: 'auto',
  flex: '2',
  padding: '20px',
  margin: '20px 20px 20px 0',
  bgcolor: '#fdf7ba',
  borderRadius: '16px',
  position: 'relative',
  overflowY: 'hidden',
  boxSizing: 'border-box',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': { display: 'none' },
};

const scrollableRanksContainer: SxProps<Theme> = {
  maxHeight: 'calc(80vh - 200px)',
  overflowY: 'auto',
  boxSizing: 'border-box',
  paddingBottom: '20px',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

const LeaderBoardToggleButtons: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  alignContent: 'center',
};

const DateSelectorButtonGroup: SxProps<Theme> = {
  fontFamily: 'RalewayRegular',
  fontSize: '14px',
  bgcolor: '#746cae',
  borderRadius: '24px',
  height: '90%',
  color: 'white',
  margin: '0',
  overflow: 'hidden',
};

const GradeSelector: SxProps<Theme> = {
  fontFamily: 'RalewayRegular',
  fontSize: '14px',
  fontWeight: 'bold',
  bgcolor: '#746cae',
  borderRadius: '24px',
  height: '90%',
  color: 'white',
  '& .Mui-selected': {
    backgroundColor: '#746cae',
    color: 'white',
  },
};

const toggleButtonStyles: SxProps<Theme> = {
  fontFamily: 'RalewayRegular',
  textTransform: 'none',
  fontSize: '14px',
  backgroundColor: 'white',
  color: '#746CAE',
  margin: '0',
  overflow: 'hidden',
  '&:hover': {
    backgroundColor: '#746CAE',
    color: 'white',
  },
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: '#746CAE',
    overflow: 'hidden',
    margin: '0',
  },
};

const ToggleButtonGroupStyles: SxProps<Theme> = {
  fontSize: '14px',
  margin: '0',
  overflow: 'hidden',
};

const studentPostionCardContainerStyles: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  ml: '10%',
  width: '60%',
  [theme.breakpoints.down('sm')]: {
    ml: '0',
    width: '100%',
    alignSelf: 'center',
    justifyContent: 'space-between',
  },
  mb: '30px',
};

const emptyStateContainerStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  textAlign: 'center',
};

export const Leaderboard: React.FC<LeaderboardPageProps> = ({
  userData,
  setLeaderboardFilter,
  setLeaderboardGrade,
  initialFilter = 'thisMonth',
  initialGrade = 'all',
}) => {
  const [selectedButton, setSelectedButton] = useState(initialFilter);
  const [grade, setGrade] = useState(initialGrade);

  useEffect(() => {
    setLeaderboardFilter(initialFilter);
    setLeaderboardGrade(initialGrade);
  }, []);

  const handleGradeChange = (event: SelectChangeEvent<string>) => {
    const newGrade = event.target.value;
    setGrade(newGrade);
    setLeaderboardGrade(newGrade);
  };

  const handleDateChange = (event: React.MouseEvent<HTMLElement>, newSelection: string | null) => {
    if (newSelection) {
      setSelectedButton(newSelection);
      setLeaderboardFilter(newSelection);
    }
  };

  return (
    <Box sx={leaderboardRightContainer}>
      <Box sx={LeaderBoardToggleButtons}>
        <Box>
          <Select sx={GradeSelector} value={grade} label='grade' onChange={handleGradeChange} displayEmpty>
            <MenuItem value='all'>All Grades</MenuItem>
            <MenuItem value='3'>Grade 3</MenuItem>
            <MenuItem value='4'>Grade 4</MenuItem>
            <MenuItem value='5'>Grade 5</MenuItem>
            <MenuItem value='6'>Grade 6</MenuItem>
            <MenuItem value='7'>Grade 7</MenuItem>
            <MenuItem value='8'>Grade 8</MenuItem>
          </Select>
        </Box>

        <Box sx={DateSelectorButtonGroup}>
          <ToggleButtonGroup
            sx={ToggleButtonGroupStyles}
            value={selectedButton}
            exclusive
            onChange={handleDateChange}
            aria-label='date selection'
          >
            <ToggleButton sx={toggleButtonStyles} value='today' aria-label='today'>
              Today
            </ToggleButton>
            <ToggleButton sx={toggleButtonStyles} value='thisWeek' aria-label='this week'>
              This Week
            </ToggleButton>
            <ToggleButton sx={toggleButtonStyles} value='thisMonth' aria-label='this month'>
              This Month
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>

      {userData.length === 0 || userData.length <= 3 ? (
        <Box sx={emptyStateContainerStyles}>
          <Typography variant='h6' color='textSecondary'>
            No leaderboard data available
          </Typography>
        </Box>
      ) : (
        <>
          {userData.length > 3 && (
            <>
              <Box sx={studentPostionCardContainerStyles}>
                <Top3StudentPositionCard
                  key={userData[1].avatar}
                  avatar={userData[1].avatar}
                  name={userData[1].name}
                  coins={userData[1].coins}
                  position={userData[1].position}
                  heightOfBar={'90px'}
                  primaryColorBar={'#73c7d3'}
                  secondaryColorBar={'#44b1c0'}
                />
                <Top3StudentPositionCard
                  key={userData[0].avatar}
                  avatar={userData[0].avatar}
                  name={userData[0].name}
                  coins={userData[0].coins}
                  position={userData[0].position}
                  heightOfBar={'130px'}
                  primaryColorBar={'#746cae'}
                  secondaryColorBar={'#423984'}
                />
                <Top3StudentPositionCard
                  key={userData[2].avatar}
                  avatar={userData[2].avatar}
                  name={userData[2].name}
                  coins={userData[2].coins}
                  position={userData[2].position}
                  heightOfBar={'70px'}
                  primaryColorBar={'#e9bcd2'}
                  secondaryColorBar={'#b87696'}
                />
              </Box>

              <Box sx={scrollableRanksContainer}>
                {userData.slice(3)?.map((kid) => (
                  <IndividualLeaderboardPositionCard
                    key={kid.position}
                    avatar={kid.avatar}
                    name={kid.name}
                    coins={kid.coins}
                    position={kid.position}
                  />
                ))}
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};
