import React, { useState } from 'react';
import { IconButton, SxProps, Theme } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useLocalStorage } from '../../context/LocalStorageContext';
import NavigationConfirmationModal from './confirmation-modal/NavigationConfirmationModal';

const buttonStyle: SxProps<Theme> = {
  backgroundColor: '#FBEC53',
  color: '#3C3077',
  '&:hover': { backgroundColor: '#FBEC53' },
  '&:focus': { backgroundColor: '#FBEC53' },
};

export const HomeButtonComponent: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const { state } = useLocalStorage();
  const [searchParams] = useSearchParams();
  const magazineId = searchParams.get('id') || '0';
  const activityId = searchParams.get('actid') || '0';
  const userId = state.userId!;
  const tenantId = state.tenantId!;
  const categoryId = state.categoryId!;

  const handleHomeButtonClick = () => {
    if (location.pathname === '/') {
      navigate('/');
    } else {
      setIsPromptOpen(true);
    }
  };

  const handleConfirmationModalClose = (confirm: boolean) => {
    setIsPromptOpen(false);
    if (confirm) {
      navigate('/');
    }
  };

  return (
    <>
      <IconButton sx={buttonStyle} aria-label='go home' component='label' onClick={handleHomeButtonClick}>
        <HomeIcon />
      </IconButton>
      <NavigationConfirmationModal
        title=''
        content='Are you sure you want to leave this page?'
        open={isPromptOpen}
        onClose={handleConfirmationModalClose}
        userId={userId}
        magazineId={magazineId}
        activityId={activityId}
        tenantId={+tenantId}
        categoryId={+categoryId}
      />
    </>
  );
};

export default HomeButtonComponent;
