import { useAsyncEffect } from '../../hooks/use-async';
import { YellowOwlContext } from '../../context/YellowOwlContext';
import { useContext, useEffect, useState } from 'react';
import { COUNTRY_CODE_KEY, useLocalStorage } from '../../context/LocalStorageContext';
import { getCodeForCountry } from '../../utils/commonUtils';
import { UserProfilePage } from '../../components/user-profile/UserProfilePage';

import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Typography, SxProps, Theme } from '@mui/material';

type UserDetails = {
  userName: string;
  userScore: number;
  avatar: string;
};

const containerStyles: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
};

const loaderStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
};

const errorIconStyles: SxProps<Theme> = {
  color: 'error.main',
  fontSize: 'large',
};

const errorTextStyles: SxProps<Theme> = {
  color: 'error.main',
  variant: 'h6',
};

export const UserProfileContainer: React.FC = () => {
  const yellowOwlApi = useContext(YellowOwlContext)!;
  const { state, getLocalStorage } = useLocalStorage();

  const [groupedBadges, setGroupedBadges] = useState<any[]>([]);
  const [userDetails, setUserDetails] = useState<UserDetails>({
    userName: '',
    userScore: 0,
    avatar: '',
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [badgesError, setBadgesError] = useState<boolean>(false);
  const [userDetailsError, setUserDetailsError] = useState<boolean>(false);

  const [badgesState] = useAsyncEffect({
    fn: async () => {
      const result = await yellowOwlApi.getBadgesForUser(state.tenantId!, state.userId!);
      return result.data;
    },
    dependencies: [],
  });

  const [userDetailsState] = useAsyncEffect<UserDetails>({
    fn: async () => {
      if (state.phoneNumber) {
        const countryCode = getLocalStorage(COUNTRY_CODE_KEY) || 'IN';
        const phoneNumberWithCountryCode = `${getCodeForCountry(countryCode)}${state.phoneNumber}`;
        const result = await yellowOwlApi.getUserDetails(phoneNumberWithCountryCode);
        return {
          userName: result?.data?.userName ?? '',
          userScore: result?.data?.userScore ?? 0,
          avatar: result?.data?.avatar ?? '',
        };
      }
      return { userName: '', userScore: 0, avatar: '' };
    },
    dependencies: [state.phoneNumber],
  });

  useEffect(() => {
    setIsLoading(badgesState.isLoading || userDetailsState.isLoading);
    setBadgesError(!!badgesState.error);
    setUserDetailsError(!!userDetailsState.error);
  }, [badgesState.isLoading, userDetailsState.isLoading, badgesState.error, userDetailsState.error]);

  useEffect(() => {
    if (badgesState.result != undefined) {
      const groupedBadges = badgesState.result.userEarnedBadges.reduce((acc: any[], badge: any) => {
        const existingBadge = acc.find((b) => b.badgeType === badge.badgeType);
        if (existingBadge) {
          existingBadge.count++;
        } else {
          acc.push({ ...badge, count: 1 });
        }
        return acc;
      }, []);
      setGroupedBadges(groupedBadges);
    }
  }, [badgesState.result]);

  useEffect(() => {
    if (userDetailsState.result != undefined) {
      setUserDetails(userDetailsState.result!);
    }
  }, [userDetailsState.result]);

  if (isLoading) {
    return (
      <Box sx={loaderStyles}>
        <CircularProgress />
      </Box>
    );
  }

  if (badgesError || userDetailsError) {
    return (
      <Box sx={containerStyles}>
        <ErrorOutlineIcon sx={errorIconStyles} />
        <Typography sx={errorTextStyles}>Failed to load user profile Please try again later.</Typography>
      </Box>
    );
  }

  return <UserProfilePage userDetails={userDetails!} groupedBadges={groupedBadges} />;
};
