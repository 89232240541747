import React, { useEffect, useState, useContext } from 'react';
import { YellowOwlContext } from '../../context/YellowOwlContext';
import { useAsyncEffect } from '../../hooks/use-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Modal, Grid, CircularProgress, AppBar, Toolbar, SxProps, Theme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import LogoutComponent from '../../components/common/LogoutComponent';
import yellowOwlLogo from '../../assets/icons/owl-sidepanel.svg';
import yellowOwlTitle from '../../assets/icons/owl-tilte.svg';
import { ModalContent } from '../../components/teachers-module/ModalContent';
import { MediaCard } from '../../components/teachers-module/MediaCard';

const loadingContainer: SxProps<Theme> = {
  height: '70vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: 2,
};

const appBar: SxProps<Theme> = {
  backgroundColor: '#3c3075',
};

const toolbar: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
};

const toolbarLogo: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
};

const logoBox: SxProps<Theme> = {
  width: '40px',
  height: '40px',
  display: 'flex',
  background: 'linear-gradient(#3C3077, #3C3077) padding-box, linear-gradient(to top, #6649D8, #FBEC53) border-box',
  borderRadius: '50em',
  border: '3px solid transparent',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '1vh',
  marginTop: '1vh',
};

const title: SxProps<Theme> = {
  marginLeft: '15px',
  width: '140px',
  height: '70px',
  color: '#FFFF00',
  marginBottom: '10px',
};

const mainContainer: SxProps<Theme> = {
  backgroundColor: '#ffeb3b',
  minHeight: '98vh',
};

const backButton: SxProps<Theme> = {
  margin: 3,
};

const gridContainer: SxProps<Theme> = {
  padding: 3,
};

type Media = {
  type: 'IMAGE' | 'VIDEO';
  url: string[] | null;
};

type UserResponse = {
  tenantId: number;
  categoryId: number;
  userId: number;
  userName: string;
  magazineId: number;
  magazineName: string;
  activityId: number;
  activityName: string;
  media: Media[];
};

const LoadingState: React.FC = () => (
  <Box sx={loadingContainer}>
    <CircularProgress />
    <Typography variant='body1' color='text.secondary'>
      Loading responses...
    </Typography>
  </Box>
);

const NoResponsesFound: React.FC = () => (
  <Box sx={loadingContainer}>
    <NoPhotographyIcon sx={{ fontSize: 60, color: 'grey.500' }} />
    <Typography variant='h6' color='text.secondary'>
      No media type responses found
    </Typography>
  </Box>
);

const ErrorState: React.FC<{ message: string }> = ({ message }) => (
  <Box
    sx={{
      height: '70vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: 2,
    }}
  >
    <Typography color='error' variant='h6'>
      {message}
    </Typography>
  </Box>
);

export const TeacherModule: React.FC = () => {
  const yellowOwlApi = useContext(YellowOwlContext);
  const [teachersData, setTeachersData] = useState<UserResponse[]>([]);
  const [selectedTeacher, setSelectedTeacher] = useState<UserResponse | null>(null);
  const [selectedMedia, setSelectedMedia] = useState<Media | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const yellowOwlHeader = {
    img: yellowOwlLogo,
    title: yellowOwlTitle,
  };
  const { tenantId, categoryId, magazineId, activityId } = location.state || {};

  useEffect(() => {
    if (!tenantId || !categoryId || !magazineId || !activityId) {
      setError('Missing required parameters');
      console.error('Missing required parameters');
    }
  }, [tenantId, categoryId, magazineId, activityId]);

  const [teacherDataState] = useAsyncEffect<UserResponse[]>({
    fn: async () => {
      try {
        if (!yellowOwlApi) throw new Error('API context not available');
        if (!tenantId || !categoryId || !magazineId || !activityId) {
          throw new Error('Missing required parameters');
        }

        const result = await yellowOwlApi.getUserResponsesForTeacher(
          String(tenantId),
          String(categoryId),
          String(magazineId),
          String(activityId)
        );

        return (result.data as { responses: UserResponse[] }).responses || [];
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
        setError(errorMessage);
        console.error('Error fetching teacher responses:', error);
        return [];
      }
    },
    dependencies: [tenantId, categoryId, magazineId, activityId, yellowOwlApi],
  });

  useEffect(() => {
    if (teacherDataState.isLoading) {
      setIsLoading(true);
      setError(null);
    } else {
      setTeachersData(
        teacherDataState.result !== undefined && teacherDataState.result !== null ? teacherDataState.result : []
      );

      setIsLoading(false);
    }
  }, [teacherDataState]);

  const hasMediaResponses =
    !isLoading &&
    teachersData.length > 0 &&
    teachersData.some((teacher) => teacher.media.some((m) => m.url && m.url.length > 0));

  const handleCardClick = (teacher: UserResponse, media: Media) => {
    setSelectedTeacher(teacher);
    setSelectedMedia(media);
  };

  const handleModalClose = () => {
    setSelectedTeacher(null);
    setSelectedMedia(null);
  };

  if (!yellowOwlApi) {
    return <ErrorState message='API context not available' />;
  }

  if (error) {
    return <ErrorState message={error} />;
  }

  return (
    <Box sx={mainContainer}>
      <AppBar position='sticky' sx={appBar}>
        <Toolbar sx={toolbar}>
          <Box sx={toolbarLogo}>
            <Box component='img' src={yellowOwlHeader.img} alt='yellowOwlLogo' sx={logoBox} />
            <Box component='img' src={yellowOwlHeader.title} alt='yellowOwlLogo' sx={title} />
          </Box>
          <Box>
            <Box sx={{ display: 'flex' }}>
              <LogoutComponent />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Button startIcon={<ArrowBackIcon />} variant='contained' onClick={() => navigate(-1)} sx={backButton}>
        Back
      </Button>

      {isLoading ? (
        <LoadingState />
      ) : !hasMediaResponses ? (
        <NoResponsesFound />
      ) : (
        <Grid container spacing={2} sx={gridContainer}>
          {teachersData.map((teacher) =>
            teacher.media.map(
              (mediaItem, index) =>
                mediaItem.url &&
                mediaItem.url.length > 0 && (
                  <Grid item xs={12} sm={6} md={4} lg={2} key={`${teacher.userId}-${mediaItem.type}-${index}`}>
                    <MediaCard
                      teacher={teacher}
                      mediaItem={mediaItem}
                      onClick={() => handleCardClick(teacher, mediaItem)}
                    />
                  </Grid>
                )
            )
          )}
        </Grid>
      )}

      <Modal open={Boolean(selectedTeacher && selectedMedia)} onClose={handleModalClose}>
        <div>
          {selectedTeacher && selectedMedia && (
            <ModalContent data={selectedTeacher} selectedMedia={selectedMedia} onClose={handleModalClose} />
          )}
        </div>
      </Modal>
    </Box>
  );
};
