import React from 'react';
import { Box, Typography, SxProps, Theme } from '@mui/material';
import { Badges, BadgesResponse } from '@yellow-owl/client-sdk';

const badgeContainerStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  margin: '10px',
};

const badgeImageStyle: React.CSSProperties = {
  width: '44px',
  height: '52px',
};

const badgeInfoContainerStyle: SxProps<Theme> = {
  display: 'flex',
  margin: '10px',
  flexDirection: 'column',
  marginLeft: '10px',
  justifyContent: 'center',
};

const badgeTypeTextStyle: SxProps<Theme> = {
  fontFamily: 'RalewayRegular',
  fontSize: '14px',
  fontWeight: 'bold',
};

const badgeDescriptionTextStyle: SxProps<Theme> = {
  color: '#625B77',
  fontFamily: 'RalewayRegular',
  fontSize: '14px',
};

const earnedBadgesTitleStyle: SxProps<Theme> = {
  fontFamily: 'RalewayRegular',
  fontSize: '14px',
  fontWeight: 'bold',
  margin: '10px',
};

const badgesScrollableContainerStyle: SxProps<Theme> = {
  maxHeight: '300px',
  // minHeight: '280px',
  overflowY: 'scroll',
  padding: '0 10px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
};

const moreBadgesContainerStyle: SxProps<Theme> = {
  marginTop: '30px',
};

const noDataContainerStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '300px',
  width: '100%',
};

const BadgeComponent: React.FC<Badges> = ({ badgeType, badgeUrl, badgeDescription }) => {
  return (
    <Box sx={badgeContainerStyle}>
      <Box>
        <img style={badgeImageStyle} src={badgeUrl} alt={badgeType} />
      </Box>
      <Box sx={badgeInfoContainerStyle}>
        <Typography sx={badgeTypeTextStyle}>
          {badgeType.charAt(0).toUpperCase() + badgeType.slice(1).toLowerCase()}
        </Typography>
        <Typography sx={badgeDescriptionTextStyle}>{badgeDescription}</Typography>
      </Box>
    </Box>
  );
};

export const Achievements: React.FC<BadgesResponse> = ({ userEarnedBadges, availableBadges }) => {
  return (
    <>
      <Box>
        <Typography sx={earnedBadgesTitleStyle}>Badges Earned</Typography>
        <Box
          sx={{
            ...badgesScrollableContainerStyle,
            ...((!userEarnedBadges || userEarnedBadges.length === 0) && { minHeight: '300px' }),
          }}
        >
          {userEarnedBadges && userEarnedBadges.length > 0 ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, width: '100%' }}>
              {userEarnedBadges.map((badge) => (
                <BadgeComponent
                  key={badge.badgeType}
                  badgeType={badge.badgeType}
                  badgeUrl={badge.badgeUrl}
                  badgeDescription={badge.badgeDescription}
                />
              ))}
            </Box>
          ) : (
            <Box sx={noDataContainerStyle}>
              <Typography sx={{ color: '#625B77' }}>No badges earned yet</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={moreBadgesContainerStyle}>
        <Typography sx={earnedBadgesTitleStyle}>More Badges</Typography>
        <Box sx={badgesScrollableContainerStyle}>
          {availableBadges?.map((badge) => (
            <BadgeComponent
              key={badge.badgeType}
              badgeType={badge.badgeType}
              badgeUrl={badge.badgeUrl}
              badgeDescription={badge.badgeDescription}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};
