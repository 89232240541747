import { UserCredential } from 'firebase/auth';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ValidateOTPPage } from '../../components/walkthrough/ValidateOTPPage';
import { AuthContext } from '../../context/Auth/AuthContext';
import { auth } from '../../firebase-config';
import { useCountDownTimer } from '../../hooks/useCountDownTimer';
import { EXPLORE_MAGAZINES } from '../../route/router';
import { toast } from 'react-toastify';
import { AuthDetails } from '../../context/Auth/AuthReducer';

export const ValidateOTPPageContainer: React.FC = () => {
  const authContext = useContext(AuthContext)!;
  const { minutes, seconds, restartTimer } = useCountDownTimer({ timeInMinutes: 10 });
  const [canVerify, setCanVerify] = useState(false);

  const otpValue = authContext.authState.otpValue;
  const {
    firebaseSignInResult: confirmation_result,
    capchaVerifierResult,
    phoneNumber,
    countryCodeValue,
  } = authContext.authState;

  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      e.preventDefault();
      e.returnValue = '';
    };

    const handleBackButton = () => {
      // Handle the back button event here
      alert('Are you sure you want to go back?');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  useEffect(() => {
    if (otpValue.length === 6 && !!confirmation_result) {
      setCanVerify(true);
    } else {
      setCanVerify(false);
    }
  }, [otpValue]);

  const handleOTPChange = (otp: string) => {
    authContext.updateAuthState({ type: 'otp', value: otp });
  };

  const clearSignInData = () => {
    authContext.updateAuthState({ type: 'phone_number', value: '' });
    authContext.updateAuthState({ type: 'otp', value: '' });
    authContext.updateAuthState({ type: 'country_code', value: 'IN' });
    authContext.updateAuthState({ type: 'country_code_value', value: '+91' });
  };

  const handleValidateOTP = async () => {
    try {
      if (confirmation_result) {
        const result: UserCredential = await confirmation_result.confirm(otpValue);
        const token = await result.user.getIdToken();
        const userState: AuthDetails = {
          token,
        };
        authContext.updateAuthState({ type: 'login', value: userState });
        clearSignInData();
        navigate(EXPLORE_MAGAZINES);
      }
    } catch (e: any) {
      // TODO: move error messages to constants
      if (e.code === 'auth/invalid-verification-code') {
        toast.error('Invalid OTP');
      }
      toast.error('Unable to validate OTP');
    }
  };

  const handleResendOTP = async () => {
    try {
      const result = await auth.signInWithPhoneNumber(`${countryCodeValue}${phoneNumber}`, capchaVerifierResult!);
      authContext.updateAuthState({ type: 'confirmation_result', value: result });
      restartTimer();
    } catch (e) {
      // TODO: later move error message to constants
      toast.error('Unable to send OTP');
    }
  };

  return (
    <ValidateOTPPage
      otpValue={otpValue}
      canVerify={canVerify}
      timer={{ minutes, seconds }}
      onOtpValueChange={handleOTPChange}
      onRequestOTPValidate={handleValidateOTP}
      onResendOTP={handleResendOTP}
    />
  );
};
