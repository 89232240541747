import { Box, Grid, IconButton, Link, Stack, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import 'react-phone-input-2/lib/bootstrap.css';
import { OwlHeader } from '../common/OwlHeader';
import { LeftPanelWalkthrough } from '../common/LeftPanelWalkthrough';
import OtpInput from 'react18-input-otp';
import { ArrowForward } from '@mui/icons-material';
import { yellowOwlMessages } from '../../Constants';
import curevLine from '../../assets/icons/curve-line.svg';

const formBox: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};

const enterOtp: SxProps<Theme> = {
  fontFamily: 'CormorantGaramondRegular',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '1.5vw',
  lineHeight: '16px',
};

const forwardIcon = {
  borderRadius: '8px',
  background: '#3C3077',
  width: '6.8vh',
  height: '6.8vh',
  ':hover': {
    backgroundColor: '#3C3077',
  },
};

export type ValidateOTPPageProps = {
  otpValue: string;
  canVerify: boolean;
  timer: {
    minutes: number;
    seconds: number;
  };
  onOtpValueChange(otp: string): void;
  onRequestOTPValidate(): void;
  onResendOTP(): void;
};

export const ValidateOTPPage: React.FC<ValidateOTPPageProps> = ({
  otpValue,
  canVerify,
  timer,
  onOtpValueChange,
  onRequestOTPValidate,
}) => {
  const { minutes, seconds } = timer;
  return (
    <Box
      sx={{
        background: `url(${curevLine}), linear-gradient(to right,#3C3077 0%,#3C3077 50%, #FBEC53 50%, #FBEC53 100%)`,
        height: '100vh',
      }}
    >
      <Grid container>
        <Grid container item>
          <Grid item xs={12}>
            <OwlHeader />
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={6}>
            <LeftPanelWalkthrough />
          </Grid>
          <Grid item xs={6} sx={formBox}>
            <Box>
              <Typography align='left' marginBottom='16px' variant='subtitle1' component='p' sx={enterOtp}>
                {yellowOwlMessages.ENTER_OTP_SENT_TO_MOBILE_NUMBER}
              </Typography>
              <Stack direction={'row'} spacing='3vh'>
                <OtpInput
                  value={otpValue}
                  onChange={onOtpValueChange}
                  numInputs={6}
                  separator={<span style={{ width: '3vh' }}></span>}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  inputStyle={{
                    border: '2px solid #3C3077',
                    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '8px',
                    width: '6vh',
                    height: '6vh',
                    fontSize: '2vh',
                    color: '#000',
                    fontWeight: '400',
                    caretColor: 'blue',
                  }}
                  focusStyle={{
                    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
                  }}
                />
                <IconButton sx={forwardIcon} disabled={!canVerify} onClick={onRequestOTPValidate}>
                  <ArrowForward sx={{ color: '#FFFFFF' }} />
                </IconButton>
              </Stack>
              <Stack sx={{ marginTop: '1vh' }} direction={'row'} justifyContent='space-between'>
                {minutes === 0 && seconds === 0 && (
                  <Typography>
                    {yellowOwlMessages.DIDNT_RECEIVE_OTP}{' '}
                    <Link underline='none' href='src/pages#'>
                      {yellowOwlMessages.RESENT_OTP}
                    </Link>
                  </Typography>
                )}
                <Typography sx={{ font: 'CormorantGaramondRegular', color: '#3C3077', fontWeight: '600' }}>
                  {`${minutes} : ${seconds}`}
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
