import React, { useState, useEffect } from 'react';
import { MyProgress } from '../../components/skill-progress/MyProgressPage';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Typography, SxProps, Theme } from '@mui/material';

type SkillProgress = {
  skillName: string;
  totalActivities: number;
  completedActivities: number;
  color: string;
};

const fetchSkillProgress = async (): Promise<SkillProgress[]> => {
  return [
    { skillName: 'Creativity', totalActivities: 45, completedActivities: 40, color: '#FFC107' },
    { skillName: 'Critical Thinking', totalActivities: 58, completedActivities: 39, color: '#607D8B' },
    { skillName: 'Communication', totalActivities: 36, completedActivities: 18, color: '#2196F3' },
    { skillName: 'Socio-emotional', totalActivities: 40, completedActivities: 13, color: '#9C27B0' },
    { skillName: 'Collaboration', totalActivities: 29, completedActivities: 11, color: '#4CAF50' },
  ];
};

const containerStyles: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
};

const loaderStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
};

const errorIconStyles: SxProps<Theme> = {
  color: 'error.main',
  fontSize: 'large',
};

const errorTextStyles: SxProps<Theme> = {
  color: 'error.main',
  variant: 'h6',
};

export const MyProgressContainer: React.FC = () => {
  const [skills, setSkills] = useState<SkillProgress[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    const getSkillProgress = async () => {
      try {
        setIsLoading(true);
        const result = await fetchSkillProgress();
        setSkills(result);
        setHasError(false);
      } catch (error) {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getSkillProgress();
  }, []);

  if (isLoading) {
    return (
      <Box sx={loaderStyles}>
        <CircularProgress />
      </Box>
    );
  }

  if (hasError) {
    return (
      <Box sx={containerStyles}>
        <ErrorOutlineIcon sx={errorIconStyles} />
        <Typography sx={errorTextStyles}>Failed to load skill progress. Please try again later.</Typography>
      </Box>
    );
  }

  return <MyProgress skills={skills} />;
};
