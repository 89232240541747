import React, { useContext, useEffect, useState } from 'react';
import { ExploreMagazinesPage } from '../../components/walkthrough/ExploreMagazinesPage';
import { YellowOwlContext } from '../../context/YellowOwlContext';
import { useAsyncEffect } from '../../hooks/use-async';
import { Magazine, MagazinePage } from '@yellow-owl/client-sdk';
import { ERROR } from '../../route/router';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../../context/LocalStorageContext';
import { gradeToAgeMap } from '../../Constants';

export const ExploreMagazinesContainer: React.FC = () => {
  const yellowOwlApi = useContext(YellowOwlContext)!;
  const { state } = useLocalStorage();
  const [magazines, setMagazines] = useState<Magazine[]>();
  const [selectedGrade, setSelectedGrade] = useState<number | null>(null);

  const [from, setFrom] = useState<number>(0);
  const [to, setTo] = useState<number>(6);
  const [isFirstPage] = useState<boolean>(true);
  const [isLastPage] = useState<boolean>(true);

  const navigate = useNavigate();

  const onScrollDown = () => {
    setFrom(from + 3);
    setTo(to + 3);
  };

  const onScrollUp = () => {
    setFrom(from - 3);
    setTo(to - 3);
  };
  const handleGradeChange = (grade: number) => {
    setSelectedGrade(grade);
  };

  const [magazinePagesState] = useAsyncEffect<MagazinePage>({
    fn: async () => {
      try {
        if (state.userId && state.categoryId && state.tenantId) {
          // Use age based on selected grade or fallback to state.age
          const age = selectedGrade ? gradeToAgeMap[selectedGrade] : Number(state.age);

          const result = await yellowOwlApi.getAllMagazines(
            state.userId,
            state.tenantId!,
            state.categoryId!,
            8,
            1,
            {
              sort: { path: [], direction: 'asc' },
              isPaid: false,
            },
            age
          );
          return result.data as any;
        }
      } catch (e) {
        navigate(ERROR);
      }
    },
    // Add selectedGrade to dependencies to trigger API call when grade changes
    dependencies: [selectedGrade, state.userId, state.categoryId, state.tenantId],
  });

  useEffect(() => {
    if (magazinePagesState.result != undefined) {
      setMagazines(magazinePagesState.result!.items);
    }
  }, [magazinePagesState]);

  return (
    <>
      {magazines !== undefined && (
        <ExploreMagazinesPage
          magazines={magazines!}
          isFirst={isFirstPage}
          isLast={isLastPage}
          onClickScrollDown={onScrollDown}
          onClickScrollUp={onScrollUp}
          selectedGrade={selectedGrade}
          onGradeChange={handleGradeChange}
        />
      )}
    </>
  );
};
