import React, { useState } from 'react';
import { Box, IconButton, SxProps, Theme, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { LogoutComponent } from '../common/LogoutComponent';
import coin from '../../assets/icons/coin.svg';
import leaderboard from '../../assets/icons/leaderboard.svg';
import achievements from '../../assets/icons/achievements.svg';
import communitywall from '../../assets/icons/communitywall.svg';
import skillProgress from '../../assets/icons/skill-progress.png';
import { theme } from '../../theme';
import { AchievementsContainer } from '../../containers/achievements/AchievementsContainer';
import { LeaderboardContainer } from '../../containers/leaderboard/LeaderboardContainer';
import { MyProgressContainer } from '../../containers/skill-progress/MyProgressContainer';
import { Badges } from '@yellow-owl/client-sdk';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface BadgesWithCount extends Badges {
  count: number;
}

type UserProfilePageProps = {
  userDetails: { userName: string; userScore: number; avatar: string };
  groupedBadges?: BadgesWithCount[];
};

type MenuItemProps = {
  image: string;
  title: string;
  isBold: boolean;
};

const contentStyle: SxProps<Theme> = {
  fontFamily: 'CormorantGaramondRegular',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '16px',
};

const mainContainerStyles: SxProps<Theme> = {
  margin: ' auto',
  height: 'auto',
  width: 'auto',
  p: 2,
  bgcolor: '#FBEC53',
};

const innerContainerStyles: SxProps<Theme> = {
  width: '100%',
  height: '95vh',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  bgcolor: '#ffffff',
  borderRadius: '16px',
  overflow: 'hidden',
  maxWidth: '100vw',
  boxSizing: 'border-box',
};

const leftContainer: SxProps<Theme> = {
  position: 'relative',
  width: 'auto',
  height: 'auto',
  flex: '1',
  padding: '20px',
  margin: '20px',
  bgcolor: '#fefbdd',
  borderRadius: '16px',
  boxSizing: 'border-box',
  overflowY: 'none',
  display: 'flex',
  flexDirection: 'column',
};

const rightContainer: SxProps<Theme> = {
  width: 'auto',
  height: 'auto',
  flex: '3',
  padding: '20px',
  margin: '20px 20px 20px 0',
  bgcolor: '#fdf7ba',
  borderRadius: '16px',
  position: 'relative',
  overflowY: 'hidden',
  boxSizing: 'border-box',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': { display: 'none' },
};

const LeftContainerTopUserBox: SxProps<Theme> = {
  mr: '55px',
  mb: '15px',
  mt: '30px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const LeftContainerTopUserAvatarStyles: SxProps<Theme> = {
  width: '90px',
  height: '90px',
  bgcolor: 'lightblue',
  borderRadius: '50%',
};

const MenuItemLeftStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '90%',
  mb: '15px',
};

const MenuItemLeftIcon: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
};

const badgesContainer = (badgeCount: number) => ({
  bgcolor: 'white',
  borderRadius: '15px',
  p: '8px',
  boxShadow: '6px 6px 10px rgba(217, 217, 217, 1)',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: badgeCount < 5 ? 'center' : 'flex-start',
  alignItems: 'center',
  overflowX: 'auto',
  overflowY: 'hidden',
  width: badgeCount > 5 ? '400px' : `${badgeCount * 80}px`,
  height: '80px',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

// Wrapper container to handle centering
const wrapperContainerStyle = (badgeCount: number) => ({
  display: 'flex',
  justifyContent: badgeCount < 5 ? 'center' : 'flex-start',
  ml: badgeCount < 5 ? 0 : '12px',
  mb: '12px',
  width: '100%',
});

const badgeStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flex: '0 0 auto',
  scrollSnapAlign: 'start',
  width: '70px',
  height: '70px',
  marginRight: '10px',
  position: 'relative',
};

const badgeIconStyle: SxProps<Theme> = {
  width: '70px',
  height: '70px',
};

const countCircleStyle: SxProps<Theme> = {
  position: 'absolute',
  bottom: '-5px',
  right: '-5px',
  width: 20,
  height: 20,
  borderRadius: '50%',
  backgroundColor: '#ff5722',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  fontSize: 12,
  fontWeight: 'bold',
};

const homeButtonStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '15px',
  left: '15px',
  backgroundColor: '#FBEC53',
  color: '#3C3077',
  '&:hover': { backgroundColor: '#FBEC53' },
  '&:focus': { backgroundColor: '#FBEC53' },
};

export const MenuItemLeft: React.FC<MenuItemProps> = ({ image, title, isBold }) => {
  return (
    <Box sx={MenuItemLeftStyles}>
      <Box sx={MenuItemLeftIcon}>
        <img src={image} alt={image} />
        <Typography
          sx={{
            fontWeight: isBold ? 'bold' : 'normal',
            fontFamily: 'RalewayRegular',
            display: 'flex',
            alignItems: 'center',
            px: '10px',
            fontSize: '16px',
          }}
          variant='h6'
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <KeyboardArrowRightIcon />
      </Box>
    </Box>
  );
};

export const UserProfilePage: React.FC<UserProfilePageProps> = ({ userDetails, groupedBadges }) => {
  const [showLeaderBoard, setShowLeaderBoard] = useState(true);
  const [showAchievements, setShowAchievements] = useState(false);
  const [showMyProgress, setShowMyProgress] = useState(false);
  const [clickedIndex, setClickedIndex] = useState<number>(0);
  const navigate = useNavigate();

  const menuItemStyles = (index: number) => ({
    color: '#625B77',
    display: 'flex',
    fontWeight: clickedIndex === index ? 'bold' : 'normal',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '22vw',
    pl: '1vw',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      width: '60vw',
    },
  });

  const handleMenuItemClick = (index: number, handleClick: () => void) => {
    setClickedIndex(index);
    handleClick();
  };

  const handleAchievementsClick = () => {
    setShowAchievements(true);
    setShowLeaderBoard(false);
    setShowMyProgress(false);
  };

  const handleLeaderboardClick = () => {
    setShowLeaderBoard(true);
    setShowAchievements(false);
    setShowMyProgress(false);
  };

  const handleCommunityWallClick = () => {
    navigate('/community-wall');
  };

  const handleMyProgressClick = () => {
    setShowMyProgress(true);
    setShowLeaderBoard(false);
    setShowAchievements(false);
  };

  const handleHomeButtonClick = () => {
    navigate('/');
  };

  return (
    <Box sx={contentStyle}>
      <Box sx={mainContainerStyles}>
        <Box sx={innerContainerStyles}>
          <Box sx={leftContainer}>
            <IconButton sx={homeButtonStyle} aria-label='go home' component='label' onClick={handleHomeButtonClick}>
              <ArrowBackIcon />
            </IconButton>
            <Box sx={LeftContainerTopUserBox}>
              <Avatar alt={userDetails.userName} src={userDetails.avatar} sx={LeftContainerTopUserAvatarStyles} />
              <Typography sx={{ fontWeight: 'bold', fontFamily: 'RalewayRegular', fontSize: '20px' }} variant='h6'>
                {userDetails?.userName}
              </Typography>
              <Typography
                sx={{ display: 'flex', alignItems: 'center', fontFamily: 'RalewayRegular', fontSize: '16px' }}
              >
                {userDetails?.userScore}
                <img src={coin} alt='coin' style={{ width: '17px', height: '17px' }} />
              </Typography>
            </Box>

            {groupedBadges && groupedBadges.length > 0 && (
              <Box sx={wrapperContainerStyle(groupedBadges.length)}>
                <Box sx={badgesContainer(groupedBadges.length)}>
                  {groupedBadges.map((badge, index) => (
                    <Box sx={badgeStyle} key={index}>
                      <Box sx={badgeIconStyle}>
                        <img src={badge.badgeUrl} alt={badge.badgeType} style={{ width: '100%', height: '100%' }} />
                      </Box>
                      {badge.count > 1 && <Box sx={countCircleStyle}>x{badge.count}</Box>}
                    </Box>
                  ))}
                </Box>
              </Box>
            )}

            <Box sx={menuItemStyles(0)} onClick={() => handleMenuItemClick(0, handleLeaderboardClick)}>
              <MenuItemLeft title='Leaderboard' image={leaderboard} isBold={clickedIndex === 0} />
            </Box>
            <Box sx={menuItemStyles(1)} onClick={() => handleMenuItemClick(1, handleCommunityWallClick)}>
              <MenuItemLeft title='Community Wall' image={communitywall} isBold={clickedIndex === 1} />
            </Box>
            <Box sx={menuItemStyles(2)} onClick={() => handleMenuItemClick(2, handleAchievementsClick)}>
              <MenuItemLeft title='Achievements' image={achievements} isBold={clickedIndex === 2} />
            </Box>
            <Box sx={menuItemStyles(3)} onClick={() => handleMenuItemClick(3, handleMyProgressClick)}>
              <MenuItemLeft title='My Progress' image={skillProgress} isBold={clickedIndex === 3} />
            </Box>
            <Box sx={menuItemStyles(3)}>
              <LogoutComponent />
            </Box>
          </Box>

          {showAchievements ? (
            <Box sx={rightContainer}>
              <AchievementsContainer />
            </Box>
          ) : showLeaderBoard ? (
            <Box sx={rightContainer}>
              <LeaderboardContainer />
            </Box>
          ) : showMyProgress ? (
            <Box sx={rightContainer}>
              <MyProgressContainer />
            </Box>
          ) : (
            ''
          )}
        </Box>
      </Box>
    </Box>
  );
};
