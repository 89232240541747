import { Dispatch, createContext, useEffect, useReducer } from 'react';
import { LSBasicUserDetailsKey, updateLocalStorage, getFromLocalStorage } from '../../utils/localStorageUtils';
import { User, UserActionType, UserReducer, initialUserState } from './UserReducer';

export type UserData = {
  name: string;
  age: number;
  email: string;
  country: string;
  state: string;
  city: string;
  tenantId: string;
  defaultCategoryId: string;
};

type UserContextProps = {
  userData: UserData;
  setUserData: Dispatch<UserActionType>;
};

export const UserContext = createContext<UserContextProps | null>(null);

export type UserProviderType = {
  children: React.ReactElement;
};

export const UserProvider: React.FC<UserProviderType> = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialUserState);

  useEffect(() => {
    const userDetails = getFromLocalStorage<UserData>(LSBasicUserDetailsKey, true) as UserData | null;
    if (userDetails) {
      if (userDetails.tenantId) {
        dispatch({ type: 'tenantId', value: userDetails.tenantId });
      }
      if (userDetails.defaultCategoryId) {
        dispatch({ type: 'defaultCategoryId', value: userDetails.defaultCategoryId });
      }
      if (userDetails.age) {
        dispatch({ type: 'age', value: userDetails.age });
      }
    }
  }, []);

  useEffect(() => {
    updateLocalStorage<User>(LSBasicUserDetailsKey, state.user, true);
  }, [state.user]);

  const contextValue: UserContextProps = {
    userData: state.user,
    setUserData: dispatch,
  };

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};
