import { Box, IconButton, Theme } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { SxProps } from '@mui/system';
import { ChangeEvent, KeyboardEvent } from 'react';

const yellowOwlInput: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '7.5vh',
  input: {
    fontFamily: 'RalewayRegular',
    background: '#FFFFFF',
    padding: '0 1vh',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1vw',
    letterSpacing: '0.1vw',
    border: 'none',
    borderRadius: '8px',
    outline: 'none',
    height: 'inherit',
  },
  button: {
    marginRight: '0.1vw',
    height: '7vh',
  },
  borderRadius: '8px',
  border: '2px solid #3C3077',
  boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
  background: '#FFFFFF',
};

const forwardIcon: SxProps<Theme> = {
  borderRadius: '0.25rem',
  background: '#3C3077',
  height: '4rem',
  width: '4rem',
  ':hover': {
    backgroundColor: '#3C3077',
  },
};

// TODO: change this to arrow function
function YellowOwlInput(props: YellowOwlInputProps) {
  const { type, submitButton, placeholder, submitButtonAction, onChangeInput, value, disableSubmitButton } = props;

  const handleKeyPressEvent = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key == 'Enter') {
      if (submitButtonAction) {
        submitButtonAction();
      }
    }
  };

  return (
    <Box sx={yellowOwlInput}>
      <input
        type={type}
        placeholder={placeholder}
        onChange={onChangeInput}
        onKeyDown={handleKeyPressEvent}
        value={value}
      />
      {submitButton && (
        <IconButton disabled={disableSubmitButton} sx={forwardIcon} onClick={submitButtonAction}>
          <ArrowForward sx={{ color: '#FFFFFF' }} />
        </IconButton>
      )}
    </Box>
  );
}

// TODO: change this to type
interface YellowOwlInputProps {
  type: string;
  submitButton?: boolean;
  placeholder: string;
  submitButtonAction?: () => void;
  // eslint-disable-next-line no-unused-vars
  onChangeInput?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  disableSubmitButton?: boolean;
}

export default YellowOwlInput;
