import { useAsyncEffect } from '../../hooks/use-async';
import { Badges, BadgesResponse } from '@yellow-owl/client-sdk';
import { YellowOwlContext } from '../../context/YellowOwlContext';
import { useContext, useEffect, useState } from 'react';
import { Achievements } from '../../components/achievements/AchievementsPage';
import { useLocalStorage } from '../../context/LocalStorageContext';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Typography, SxProps, Theme } from '@mui/material';

interface BadgesWithCount extends Badges {
  count: number;
}

const containerStyles: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
};

const loaderStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
};

const errorIconStyles: SxProps<Theme> = {
  color: 'error.main',
  fontSize: 'large',
};

const errorTextStyles: SxProps<Theme> = {
  color: 'error.main',
  variant: 'h6',
};

export const AchievementsContainer: React.FC = () => {
  const yellowOwlApi = useContext(YellowOwlContext)!;
  const { state } = useLocalStorage();

  const [badgesContent, setBadgesContent] = useState<BadgesResponse>({
    userEarnedBadges: [],
    availableBadges: [],
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [badgesError, setBadgesError] = useState<boolean>(false);

  const [badgesState] = useAsyncEffect<BadgesResponse>({
    fn: async () => {
      const result = await yellowOwlApi.getBadgesForUser(state.tenantId!, state.userId!);
      return result.data;
    },
    dependencies: [],
  });

  useEffect(() => {
    setIsLoading(badgesState.isLoading);
    setBadgesError(!!badgesState.error);
  }, [badgesState.isLoading, badgesState.error]);

  useEffect(() => {
    if (badgesState.result != undefined) {
      setBadgesContent(badgesState.result!);
    }
  }, [badgesState.result]);

  const groupedBadges: BadgesWithCount[] = badgesContent?.userEarnedBadges?.reduce((acc: BadgesWithCount[], badge) => {
    const existingBadge = acc.find((b) => b.badgeType === badge.badgeType);
    if (existingBadge) {
      existingBadge.count++;
    } else {
      acc.push({ ...badge, count: 1 });
    }
    return acc;
  }, []);

  if (isLoading) {
    return (
      <Box sx={loaderStyles}>
        <CircularProgress />
      </Box>
    );
  }

  if (badgesError) {
    return (
      <Box sx={containerStyles}>
        <ErrorOutlineIcon sx={errorIconStyles} />
        <Typography sx={errorTextStyles}>Failed to load badges. Please try again later.</Typography>
      </Box>
    );
  }

  return <Achievements userEarnedBadges={groupedBadges} availableBadges={badgesContent.availableBadges} />;
};
