import { Box, CardMedia, Typography, IconButton, Paper, Chip, SxProps, Theme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import VideoFileIcon from '@mui/icons-material/VideoFile';

type ModalContentProps = {
  data: UserResponse;
  selectedMedia: Media;
  onClose: () => void;
};
type Media = {
  type: 'IMAGE' | 'VIDEO';
  url: string[] | null;
};

type UserResponse = {
  tenantId: number;
  categoryId: number;
  userId: number;
  userName: string;
  magazineId: number;
  magazineName: string;
  activityId: number;
  activityName: string;
  media: Media[];
};

const noMediaContainer: SxProps<Theme> = {
  height: '140px',
  bgcolor: 'grey.100',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: 1,
};

const modalPaper: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '95%', sm: '85%', md: '75%' },
  maxWidth: '800px',
  maxHeight: '90vh',
  overflow: 'auto',
  borderRadius: 2,
  bgcolor: 'background.paper',
  boxShadow: 24,
};

const closeButton: SxProps<Theme> = {
  position: 'absolute',
  right: 15,
  top: 15,
  bgcolor: 'rgba(255, 255, 255, 0.8)',
  '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.9)' },
  zIndex: 1,
};

const mediaContainer: SxProps<Theme> = {
  position: 'relative',
  width: '100%',
  paddingTop: '56.25%',
};

const mediaContent: SxProps<Theme> = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  bgcolor: 'black',
};

const mediaTypeChip = (type: 'IMAGE' | 'VIDEO') => ({
  position: 'absolute',
  top: 8,
  left: 8,
  backgroundColor: type === 'IMAGE' ? '#4CC9FE' : 'yellow',
  color: 'black',
});

const NoMediaPlaceholder: React.FC<{ type: 'IMAGE' | 'VIDEO' }> = ({ type }) => (
  <Box sx={noMediaContainer}>
    {type === 'IMAGE' ? (
      <ImageNotSupportedIcon sx={{ fontSize: 40, color: 'grey.500' }} />
    ) : (
      <VideoFileIcon sx={{ fontSize: 40, color: 'grey.500' }} />
    )}
    <Typography variant='caption' color='text.secondary'>
      No {type.toLowerCase()} available
    </Typography>
  </Box>
);

export const ModalContent: React.FC<ModalContentProps> = ({ data, selectedMedia, onClose }) => {
  return (
    <Paper sx={modalPaper}>
      <Box sx={{ position: 'relative' }}>
        <IconButton onClick={onClose} sx={closeButton}>
          <CloseIcon />
        </IconButton>

        <Box sx={{ p: 2 }}>
          {selectedMedia.type === 'IMAGE' && selectedMedia.url && selectedMedia.url.length > 0 ? (
            <Box sx={mediaContainer}>
              <CardMedia
                component='img'
                image={selectedMedia.url[0]}
                alt={`Image from ${data.userName}`}
                sx={mediaContent}
              />
              <Chip label='Image' color='primary' size='small' sx={mediaTypeChip('IMAGE')} />
            </Box>
          ) : selectedMedia.type === 'VIDEO' && selectedMedia.url && selectedMedia.url.length > 0 ? (
            <Box sx={mediaContainer}>
              <Box sx={mediaContent}>
                <video
                  controls
                  playsInline
                  controlsList='nodownload'
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                >
                  <source src={selectedMedia.url[0]} type='video/webm' />
                  Your browser does not support the video tag.
                </video>
              </Box>
              <Chip label='Video' color='secondary' size='small' sx={mediaTypeChip('VIDEO')} />
            </Box>
          ) : (
            <NoMediaPlaceholder type={selectedMedia.type} />
          )}
        </Box>

        <Box sx={{ p: 3 }}>
          <Typography variant='h5' gutterBottom>
            {data.userName}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
            <Chip label={data.activityName} color='primary' size='small' />
            <Chip label={data.magazineName} color='secondary' size='small' />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};
