import { BookComponent } from '../common/BookComponent';
import { Grid, SxProps, Theme, Typography } from '@mui/material';
import { yellowOwlMessages } from '../../Constants';
import { isMobile } from 'react-device-detect';
import { MobileInformataionPage } from '../common/MobileInformationPage';

const leftPageContentTitle: SxProps<Theme> = {
  fontWeight: '700',
  fontSize: '3vh',
  fontFamily: 'CormorantGaramondRegular',
  textAlign: 'left',
  color: '#5942CB',
  marginLeft: '4vh',
  marginTop: '-2vh',
};
const rightPageContentTitle: SxProps<Theme> = {
  fontWeight: '700',
  fontSize: '3vh',
  fontFamily: 'CormorantGaramondRegular',
  textAlign: 'left',
  color: '#5942CB',
  marginLeft: '4vh',
  marginTop: '2vh',
};

const leftPageContent: SxProps<Theme> = {
  fontWeight: '600',
  fontSize: '1.7vh',
  fontFamily: 'CormorantGaramondRegular',
  textAlign: 'justify',
  lineHeight: '4vh',
  marginLeft: '4vh',
};
const rightPageContent: SxProps<Theme> = {
  fontWeight: '600',
  fontSize: '1.7vh',
  fontFamily: 'CormorantGaramondRegular',
  textAlign: 'justify',
  lineHeight: '4vh',
  marginLeft: '4vh',
  marginRight: '2vh',
};

export const PrivacyPolicyPage = () => {
  const leftContent = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography align={'left'} gutterBottom sx={leftPageContentTitle} variant={'subtitle2'}>
          <b>{yellowOwlMessages.TERMS_AND_CONDITIONS}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography align={'center'} gutterBottom sx={leftPageContent} variant={'subtitle2'}>
          {yellowOwlMessages.termsAndConditions}
          <br></br>
          {yellowOwlMessages.eligibility}
          <br></br>
          {yellowOwlMessages.userAccounts}
          <br></br>
          {yellowOwlMessages.content}
          <br></br>
          {yellowOwlMessages.intellectualProperty}
          <br></br>
          {yellowOwlMessages.userConduct}
          <br></br>
          {yellowOwlMessages.privacy}
          <br></br>
          {yellowOwlMessages.termination}
          <br></br>
          {yellowOwlMessages.contactAdmin}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={leftPageContent}></Grid>
    </Grid>
  );

  const rightContent = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography align={'left'} gutterBottom sx={rightPageContentTitle} variant={'subtitle1'}>
          <b>{yellowOwlMessages.PRIVACY_POLICY}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography align={'center'} gutterBottom sx={rightPageContent} variant={'subtitle2'}>
          {yellowOwlMessages.privacyPolicy}
          <br></br>
          {yellowOwlMessages.collectionOfInformation}
          <br></br>
          {yellowOwlMessages.useOfInformation}
          <br></br>
          {yellowOwlMessages.disclosureOfInformation}
          <br></br>
          {yellowOwlMessages.security}
          <br></br>
          {yellowOwlMessages.thirdParty}
          <br></br>
          {yellowOwlMessages.childrensPrivacy}
          <br></br>
          {yellowOwlMessages.changesToPrivacyPolicy}
          <br></br>
          {/* {yellowOwlMessages.adminContact} */}
        </Typography>
      </Grid>
    </Grid>
  );

  const mobileContent = (
    <>
      <Typography align={'left'} gutterBottom sx={leftPageContentTitle} variant={'subtitle2'}>
        <b>{yellowOwlMessages.TERMS_AND_CONDITIONS}</b>
      </Typography>
      <Typography align={'center'} gutterBottom sx={leftPageContent} variant={'subtitle2'}>
        {yellowOwlMessages.termsAndConditions}
        <br></br>
        {yellowOwlMessages.eligibility}
        <br></br>
        {yellowOwlMessages.userAccounts}
        <br></br>
        {yellowOwlMessages.content}
        <br></br>
        {yellowOwlMessages.intellectualProperty}
        <br></br>
        {yellowOwlMessages.userConduct}
        <br></br>
        {yellowOwlMessages.privacy}
        <br></br>
        {yellowOwlMessages.termination}
        <br></br>
        {yellowOwlMessages.contactAdmin}
      </Typography>
      <br />
      <br />
      <Typography align={'left'} gutterBottom sx={rightPageContentTitle} variant={'subtitle1'}>
        <b>{yellowOwlMessages.PRIVACY_POLICY}</b>
      </Typography>
      <Typography align={'center'} gutterBottom sx={rightPageContent} variant={'subtitle2'}>
        {yellowOwlMessages.privacyPolicy}
        <br></br>
        {yellowOwlMessages.collectionOfInformation}
        <br></br>
        {yellowOwlMessages.useOfInformation}
        <br></br>
        {yellowOwlMessages.disclosureOfInformation}
        <br></br>
        {yellowOwlMessages.security}
        <br></br>
        {yellowOwlMessages.thirdParty}
        <br></br>
        {yellowOwlMessages.childrensPrivacy}
        <br></br>
        {yellowOwlMessages.changesToPrivacyPolicy}
        <br></br>
        {/* {yellowOwlMessages.adminContact} */}
      </Typography>
    </>
  );

  return (
    <>
      {!isMobile ? (
        <BookComponent leftContent={leftContent} rightContent={rightContent} hideButtons={false} />
      ) : (
        <MobileInformataionPage content={mobileContent} />
      )}
    </>
  );
};
