import { createTheme } from '@mui/material/styles';
import './assets/fonts/CormorantGaramond-Regular.ttf';
import './assets/fonts/Macondo-Regular.ttf';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#635992',
      main: '#3C3077',
      dark: '#2A2153',
    },
    secondary: {
      light: '#FBEF75',
      main: '#FBEC53',
      dark: '#AFA53A',
    },
  },
  typography: {
    fontFamily: ['RalewayRegular', 'CormorantGaramondRegular', 'MacondoRegular'].join(','),
    button: {
      fontWeight: 'bold',
      letterSpacing: '1px',
    },
  },
});
