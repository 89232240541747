import { Box, Grid, SxProps, Theme } from '@mui/material';
import curvedLine from '../../assets/icons/curve-line.svg';
import { HomeButtonComponent } from './HomeButtonComponent';
import { IndexPageButtonComponent } from './IndexPageButtonComponent';
import { UserProfileComponent } from './UserProfileComponent';
import userProfileYellow from '../../assets/icons/userProfileYellow.svg';

const leftPaperGrid: SxProps<Theme> = {
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'right',
};

const actionButtonBox: SxProps<Theme> = {
  width: '100%',
  height: 'calc(100vh - 40px)',
  pt: '40px',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
};

const rightPaperGrid: SxProps<Theme> = {
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
};

const leftPaperDesign: SxProps<Theme> = {
  clipPath: 'polygon(100% 2%, 100% 100%, 0 100%, 0 0)',
  width: '43vw',
  height: '95vh',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};
const rightPaperDesign: SxProps<Theme> = {
  clipPath: 'polygon(100% 0, 100% 100%, 0 100%, 0 2%)',
  width: '43vw',
  height: '95vh',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  position: 'relative',
};

export type BookComponentProps = {
  leftContent: React.ReactElement;
  rightContent: React.ReactElement;
  hideButtons: boolean;
};

export const BookComponent: React.FC<BookComponentProps> = ({ leftContent, rightContent, hideButtons }) => {
  return (
    <Box
      sx={{
        background: `url(${curvedLine}), linear-gradient(to right,#3C3077 0%,#3C3077 50%, #FBEC53 50%, #FBEC53 100%)`,
        height: '100vh',
      }}
    >
      <Grid container>
        <Grid item xs={6}>
          <Box sx={leftPaperGrid}>
            <Grid xs={1.8}>
              <Box sx={actionButtonBox}>
                {hideButtons && (
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Box>
                        <HomeButtonComponent />
                      </Box>
                      <Box ml={'10px'}>
                        <IndexPageButtonComponent />
                      </Box>
                    </Box>
                    <Box sx={{ alignSelf: 'center' }}>
                      <UserProfileComponent userProfileIcon={userProfileYellow} />
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid xs={10.6}>
              <Box sx={leftPaperDesign}>{leftContent}</Box>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={rightPaperGrid}>
            <Grid item xs={10.4}>
              <Box sx={rightPaperDesign}>
                <>{rightContent}</>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
