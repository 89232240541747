import React, { useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useAsync } from '../../../hooks/use-async';
import { YellowOwlContext } from '../../../context/YellowOwlContext';

type ConfirmationModalType = {
  title: string;
  content: string;
  open: boolean;
  onClose: (value: boolean) => void;
  userId: string;
  magazineId: string;
  activityId: string;
  tenantId: number;
  categoryId: number;
};

export default function NavigationConfirmationModal(props: ConfirmationModalType) {
  const { title, content, open, onClose, userId, magazineId, activityId, tenantId, categoryId } = props;
  const yellowOwlApi = useContext(YellowOwlContext)!;

  const [{ isLoading }, addUserTimeSpent] = useAsync({
    fn: async () => {
      const key = `${userId}_${magazineId}_${activityId}`;
      const storedTimeSpent = localStorage.getItem('timeSpent');
      const timeSpentData = storedTimeSpent ? JSON.parse(storedTimeSpent) : {};
      const userTimeSpent = timeSpentData[key] ? timeSpentData[key] : 0;

      const result = await yellowOwlApi.addUserTimeSpent({
        userId: +userId,
        magazineId: +magazineId,
        activityId: +activityId,
        tenantId: tenantId,
        categoryId: categoryId,
        timeSpent: +userTimeSpent,
      });

      return result.data;
    },
  });

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = async () => {
    await addUserTimeSpent();
    onClose(true);
  };

  return (
    <Dialog keepMounted open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions sx={{ marginBottom: '10px' }}>
        <Button autoFocus onClick={handleCancel} disableElevation>
          Cancel
        </Button>
        {isLoading ? (
          <LoadingButton
            color='secondary'
            loading={true}
            loadingPosition='start'
            startIcon={<SaveIcon />}
            variant='contained'
            sx={{ marginLeft: 1 }}
          >
            Ok
          </LoadingButton>
        ) : (
          <Button color='secondary' onClick={handleOk} variant='contained' sx={{ marginLeft: 1 }}>
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
