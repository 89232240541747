import { useContext } from 'react';
import { useAsync } from '../hooks/use-async';
import { YellowOwlContext } from '../context/YellowOwlContext';

type LoggedInEventData = {
  userId: string;
  deviceId: string;
  dob: string;
  phoneNumber: string;
  email: string;
  country: string;
  state: string;
  city: string;
};

export const useUserAuthEvents = () => {
  const yellowOwlApi = useContext(YellowOwlContext)!;

  const [sendLoggedInEventState, sendLoggedInEvent] = useAsync({
    fn: async () => {
      const userId = localStorage.getItem('uid');
      const email = localStorage.getItem('parentEmail');
      const dob = localStorage.getItem('dateOfBirth');
      const country = localStorage.getItem('country');
      const state = localStorage.getItem('state');
      const city = localStorage.getItem('city');
      const phoneNumber = localStorage.getItem('parentPhone');

      const deviceId = 'placeholder-device-id';
      if (!userId) {
        throw new Error('User ID is required but not found in localStorage');
      }

      const eventData: LoggedInEventData = {
        userId,
        deviceId,
        dob: dob || '1970-01-01',
        phoneNumber: phoneNumber || 'Unknown',
        email: email || 'unknown@example.com',
        country: country || 'Unknown',
        state: state || 'Unknown',
        city: city || 'Unknown',
      };

      const result = await yellowOwlApi.saveUserLoggedInEvent(eventData);
      //   console.log('Login event sent successfully');
      //   console.log(result.data);
      return result.data;
    },
  });

  return { sendLoggedInEventState, sendLoggedInEvent };
};

export const useUserLoggedOutEvents = () => {
  const yellowOwlApi = useContext(YellowOwlContext)!;

  const [sendLoggedOutEventState, sendLoggedOutEvent] = useAsync({
    fn: async () => {
      const userId = localStorage.getItem('uid');
      const email = localStorage.getItem('parentEmail');
      const dob = localStorage.getItem('dateOfBirth');
      const country = localStorage.getItem('country');
      const state = localStorage.getItem('state');
      const city = localStorage.getItem('city');
      const phoneNumber = localStorage.getItem('parentPhone');

      const deviceId = 'placeholder-device-id';
      if (!userId) {
        throw new Error('User ID is required but not found in localStorage');
      }

      const eventData: LoggedInEventData = {
        userId,
        deviceId,
        dob: dob || '1970-01-01',
        phoneNumber: phoneNumber || 'Unknown',
        email: email || 'unknown@example.com',
        country: country || 'Unknown',
        state: state || 'Unknown',
        city: city || 'Unknown',
      };

      const result = await yellowOwlApi.saveUserLoggedOutEvent(eventData);
      //   console.log('Login event sent successfully');
      //   console.log(result.data);
      return result.data;
    },
  });

  return { sendLoggedOutEventState, sendLoggedOutEvent };
};
