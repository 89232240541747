import React from 'react';
import { ActivitySkillType, InputContent, QuestionData, QuestionFormat } from '@yellow-owl/client-sdk';
import MagazineTextArea from '../components/common/magazine-textarea/MagazineTextArea';
import { Stack, SxProps, Theme, Typography } from '@mui/material';
import ThinkOutsideImageUploader from '../components/common/magazine-camera-file-uploader/ThinkOutsideImageUploader';
import { MagazineVoiceInput } from '../components/common/magazine-voice-input/MagazineVoiceInput';
import { MagazineVideoInput } from '../components/common/magazine-video-input/MagazineVideoInput';
import MagazineMCQ from '../components/common/magazine-mcq-questions/MagazineMCQ';

const labelStyle: SxProps<Theme> = {
  marginTop: '1vh',
  fontWeight: '600',
  fontSize: '2.3vh',
  fontFamily: 'CormorantGaramondRegular',
  textAlign: 'left',
  lineHeight: '4vh',
};

export const getInputComponent = (
  magazineId: string,
  magazineName: string,
  ActivityName: string,
  userId: string,
  questionData: QuestionData,
  index: number,
  inputContent: Array<InputContent>,
  activityId: string,
  tenantId: number,
  categoryId: number,
  skill: Array<ActivitySkillType>,
  globalInputState: boolean,
  onUpdateInputContents: (input: Array<InputContent>) => boolean
) => {
  return (
    <>
      <Typography variant={'body1'} sx={labelStyle}>
        {questionData.question}
      </Typography>
      {questionData.questionFormat.map((questionFormat) => {
        return getComponent(
          questionFormat,
          index,
          inputContent,
          questionData.id,
          magazineId,
          magazineName,
          userId,
          activityId,
          ActivityName,
          tenantId,
          categoryId,
          skill,
          globalInputState,
          onUpdateInputContents
        );
      })}
    </>
  );
};

const getComponent = (
  questionFormat: QuestionFormat | any,
  index: number,
  inputContent: Array<InputContent>,
  questionDataId: number,
  magazineId: string,
  magazineName: string,
  userId: string,
  activityId: string,
  ActivityName: string,
  tenantId: number,
  categoryId: number,
  skill: Array<ActivitySkillType>,
  globalInputState: boolean,
  onUpdateInputContents: (input: Array<InputContent>) => boolean
) => {
  switch (questionFormat.format.type) {
    case 'DESCRIPTIVE': {
      return (
        <MagazineTextArea
          questionFormat={questionFormat}
          activityId={activityId}
          magazineId={magazineId}
          userId={userId}
          value={questionFormat.format.response}
          key={questionFormat.format.id}
          rows={questionFormat.format.noOfLines ?? 1}
          placeholder={questionFormat.format.placeholder ?? 'Enter your answer'}
          sx={{ width: '100%' }}
          inputContent={inputContent}
          questionId={questionDataId}
          questionFormatId={questionFormat.format.id}
          tenantId={tenantId}
          categoryId={categoryId}
          skill={skill}
          globalInputState={globalInputState}
          onUpdateInputContents={onUpdateInputContents}
        />
      );
    }
    case 'DESCRIPTIVE_WITH_IMAGE': {
      return (
        <Stack direction={'row'} justifyContent={'space-between'}>
          <img src={questionFormat.format.media_url[0].url} style={{ width: '20vh' }} />
          <MagazineTextArea
            questionFormat={questionFormat}
            activityId={activityId}
            magazineId={magazineId}
            userId={userId}
            value={questionFormat.format.response}
            key={questionFormat.format.id}
            rows={questionFormat.format.noOfLines ?? 1}
            placeholder={questionFormat.format.placeholder ?? 'Enter your answer'}
            sx={{ width: '100%' }}
            inputContent={inputContent}
            questionId={questionDataId}
            questionFormatId={questionFormat.format.id}
            tenantId={tenantId}
            categoryId={categoryId}
            skill={skill}
            globalInputState={globalInputState}
            onUpdateInputContents={onUpdateInputContents}
          />
        </Stack>
      );
    }
    case 'IMAGE': {
      return (
        <ThinkOutsideImageUploader
          questionFormat={questionFormat}
          image={questionFormat.format.referenceImage!}
          inputContent={inputContent}
          questionFormatId={questionFormat.format.id}
          key={questionFormat.format.id}
          questionId={questionDataId}
          userId={userId}
          activityId={activityId}
          activityName={ActivityName}
          magazineId={magazineId}
          magazineName={magazineName}
          tenantId={tenantId}
          categoryId={categoryId}
          skill={skill}
          url={questionFormat.format?.response ?? ''}
          globalInputState={globalInputState}
          onUpdateInputContents={onUpdateInputContents}
        />
      );
    }
    case 'AUDIO': {
      return (
        <MagazineVoiceInput
          questionFormat={questionFormat}
          inputContent={inputContent}
          questionFormatId={questionFormat.format.id}
          userId={userId}
          key={questionFormat.format.id}
          magazineId={magazineId}
          activityId={activityId}
          tenantId={tenantId}
          categoryId={categoryId}
          skill={skill}
          questionId={questionDataId}
          url={questionFormat.format?.response?.[0] ?? ''}
          globalInputState={globalInputState}
          onUpdateInputContents={onUpdateInputContents}
        />
      );
    }
    case 'VIDEO': {
      return (
        <MagazineVideoInput
          questionFormat={questionFormat}
          inputContent={inputContent}
          questionFormatId={questionFormat.format.id}
          userId={userId}
          magazineId={magazineId}
          activityId={activityId}
          tenantId={tenantId}
          categoryId={categoryId}
          skill={skill}
          questionId={questionDataId}
          url={questionFormat.format?.response?.[0] ?? ''}
          globalInputState={globalInputState}
          onUpdateInputContents={onUpdateInputContents}
        />
      );
    }
    case 'MCQ': {
      return (
        <MagazineMCQ
          questionFormat={questionFormat}
          inputContent={inputContent}
          questionFormatId={questionFormat.format.id}
          userId={userId}
          magazineId={magazineId}
          activityId={activityId}
          tenantId={tenantId}
          categoryId={categoryId}
          skill={skill}
          questionId={questionDataId}
          globalInputState={globalInputState}
          onUpdateInputContents={onUpdateInputContents}
        />
      );
    }
    default:
      return <div />;
  }
};
