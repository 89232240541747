import { Box, SxProps, Theme, Typography } from '@mui/material';
import owlHeader from '../../assets/icons/owl-header.svg';

const headerBox: SxProps<Theme> = {
  paddingTop: '1vh',
  minHeight: '74px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transform: 'translateX(-1.3%)',
};

const yellowText: SxProps<Theme> = {
  fontFamily: 'MacondoRegular',
  fontWeight: '400',
  fontSize: '6vh',
  lineHeight: '16px',
  fontStyle: 'normal',
  color: '#FFFFFF',
};

const owlText: SxProps<Theme> = {
  fontFamily: 'MacondoRegular',
  fontWeight: '400',
  fontSize: '6vh',
  lineHeight: '16px',
  fontStyle: 'normal',
  color: '#3C3077',
  marginLeft: '14px',
};

const imgStyle = { width: '101px', height: '102px', marginLeft: '14px' };

export const OwlHeader: React.FC = () => {
  return (
    <Box sx={headerBox}>
      <Typography sx={yellowText} variant='body2'>
        Yellow
      </Typography>
      <img src={owlHeader} style={imgStyle} alt='yellow-owl-header' />
      <Typography sx={owlText} variant='body2'>
        Owl
      </Typography>
    </Box>
  );
};
