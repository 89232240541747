import { createContext, Dispatch, useEffect, useReducer } from 'react';
import { AuthActionType, AuthDetails, AuthReducer, AuthState, initialAuthState } from './AuthReducer';
import { LSAuthDetails, LSBasicUserDetailsKey, updateLocalStorage } from '../../utils/localStorageUtils';
import { UserData } from '../User/UserContext';

export type AuthContextType = {
  authState: AuthState;
  updateAuthState: Dispatch<AuthActionType>;
};

export const AuthContext = createContext<AuthContextType | null>(null);

export type AuthContextProviderType = {
  children: React.ReactElement;
};

export const AuthContextProvider: React.FC<AuthContextProviderType> = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialAuthState);

  useEffect(() => {
    // each and every time when state is updated, it will update local storage also
    updateLocalStorage<AuthDetails>(LSAuthDetails, state.authDetails, true);
  }, [state.authDetails]);

  useEffect(() => {
    // each and every time when state is updated, it will update local storage also
    updateLocalStorage<UserData>(LSBasicUserDetailsKey, state.basicUserData, true);
  }, [state.basicUserData]);

  const contextValue: AuthContextType = {
    authState: state,
    updateAuthState: dispatch,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
