import { Box, Typography, SxProps, Theme, Avatar } from '@mui/material';
import { theme } from '../../theme';
import coin from '../../assets/icons/coin.svg';

type Top3StudentPositionCardProps = {
  avatar: string;
  name: string;
  coins: number;
  position: number;
  heightOfBar: string;
  primaryColorBar: string;
  secondaryColorBar: string;
};

const studentPostionCardStyles: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
  },
  marginTop: '-45px',
};

const avatarStyles: SxProps<Theme> = {
  display: 'flex',
  alignSelf: 'center',
  bgcolor: 'lightblue',
  borderRadius: '50%',
  height: '50px',
  width: '50px',
};

const nameTextStyles: SxProps<Theme> = {
  fontWeight: 'bold',
  fontFamily: 'RalewayRegular',
  fontSize: '16px',
};

const coinsTextStyles: SxProps<Theme> = {
  fontFamily: 'RalewayRegular',
  fontSize: '12px',
};

const barContainerStyles: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  width: '100%',
  marginTop: '10px',
};

const primaryBarStyles = (primaryColorBar: string, heightOfBar: string | number): SxProps<Theme> => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  bgcolor: primaryColorBar,
  width: '120px',
  height: heightOfBar,
  borderRadius: '12px 12px 0 0',
  [theme.breakpoints.down('sm')]: {
    alignSelf: 'center',
    width: '50px',
  },
});

const secondaryBarStyles = (secondaryColorBar: string): SxProps<Theme> => ({
  bgcolor: secondaryColorBar,
  height: '40px',
  width: '40px',
  borderRadius: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const positionTextStyles: SxProps<Theme> = {
  color: 'white',
};

export const Top3StudentPositionCard: React.FC<Top3StudentPositionCardProps> = ({
  avatar,
  name,
  coins,
  position,
  heightOfBar,
  primaryColorBar,
  secondaryColorBar,
}) => {
  return (
    <Box sx={studentPostionCardStyles}>
      <Avatar sx={avatarStyles} alt={name} src={avatar} />
      <Typography sx={nameTextStyles}>{name}</Typography>
      <Typography sx={coinsTextStyles}>
        {coins} <img src={coin} alt='coin' style={{ width: '17px', height: '17px' }} />
      </Typography>
      <Box sx={barContainerStyles}>
        <Box sx={primaryBarStyles(primaryColorBar, heightOfBar)}>
          <Box sx={secondaryBarStyles(secondaryColorBar)}>
            <Typography sx={positionTextStyles}>{position}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
