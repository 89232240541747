import produce from 'immer';
import { LSBasicUserDetailsKey, getFromLocalStorage } from '../../utils/localStorageUtils';
import { UserData } from './UserContext';

export type User = {
  name: string;
  age: number;
  email: string;
  country: string;
  state: string;
  city: string;
  tenantId: string;
  defaultCategoryId: string;
};

const baseUserData: User = {
  name: '',
  age: 6,
  email: '',
  country: '',
  state: '',
  city: '',
  tenantId: '',
  defaultCategoryId: '',
};

export type UserState = {
  // show in ui
  user: User;
  // for local storage
  userDetails: UserData | null;
};

export const initialUserState: UserState = {
  user: baseUserData,
  userDetails: getFromLocalStorage<UserData>(LSBasicUserDetailsKey, true) as UserData | null,
};

export type UserActionType = {
  type: 'name' | 'age' | 'email' | 'country' | 'state' | 'city' | 'tenantId' | 'defaultCategoryId';
  value: string | number;
};

export const UserReducer = (state: UserState, action: UserActionType) => {
  const { type, value } = action;

  switch (type) {
    case 'age':
      return produce(state, (draft) => {
        draft.user.age = value as number;
        return draft;
      });
    case 'name':
      return produce(state, (draft) => {
        draft.user.name = value as string;
        return draft;
      });
    case 'email':
      return produce(state, (draft) => {
        draft.user.email = value as string;
        return draft;
      });
    case 'country':
      return produce(state, (draft) => {
        draft.user.country = value as string;
        return draft;
      });
    case 'state':
      return produce(state, (draft) => {
        draft.user.state = value as string;
        return draft;
      });
    case 'city':
      return produce(state, (draft) => {
        draft.user.city = value as string;
        return draft;
      });
    case 'tenantId':
      return produce(state, (draft) => {
        draft.user.tenantId = value as string;
        return draft;
      });
    case 'defaultCategoryId':
      return produce(state, (draft) => {
        draft.user.defaultCategoryId = value as string;
        return draft;
      });
  }
};
