import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Backdrop } from '@mui/material';

type ConfirmationModalType = {
  title: string;
  content: string;
  open: boolean;
  onClose: (value: boolean) => void;
};

const dialogTitle = {
  fontSize: '2.5rem',
  fontFamily: 'Schoolbell',
  fontWeight: '900',
};
const dialogContent = {
  fontSize: '1.4rem',
  fontFamily: 'Schoolbell',
  fontWeight: '700',
};

const confirmationModal = {
  '& .MuiDialog-paper': {
    width: '420px',
    borderRadius: '12px',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    border: '5px solid #3C3077',
    boxShadow: '1px 2px 6px 3px #b2acd3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const okButton = {
  width: '90%',
  fontFamily: 'Schoolbell',
  fontWeight: '700',
  fontSize: '1.2rem',
  color: 'black',
};

const dialogActions = {
  marginBottom: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(251, 236, 83, 0.5)',
  borderRadius: '8px',
  border: '2px solid black',
  height: '2vh',
};

const backdropStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
};

export default function MandatoryConfirmation(props: ConfirmationModalType) {
  const { title, content, open, onClose } = props;

  const handleOk = () => {
    onClose(true);
  };

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      keepMounted
      open={open}
      onClose={handleClose}
      sx={confirmationModal}
      BackdropComponent={Backdrop}
      BackdropProps={{ style: backdropStyle }}
    >
      <DialogTitle sx={dialogTitle}>{title}</DialogTitle>
      <DialogContent sx={dialogContent}>{content}</DialogContent>
      <DialogActions sx={dialogActions}>
        <Button onClick={handleOk} sx={okButton}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
